/* --------------------------- */
/* Renter Tabs */
/* --------------------------- */

@use 'sass:math';

.tab {
  --figure-max-height: 440px;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  min-height: var(--figure-max-height);
}

.text {
  --button-margin: 25px;
  --label-font-size: 18px;
  --label-margin: 20px;

  flex-grow: 1;
  flex-shrink: 0;
  max-width: var(--text-max-width, var(--max-width, 500px));
  padding-top: 70px;

  :global {
    .icon-text {
      color: var(--purple-color);
      font-size: var(--label-font-size);
      margin-bottom: var(--label-margin);

      &:not(.icon-text-cash-stroke) {
        margin-left: -1px;
      }
    }

    .button {
      margin-top: var(--button-margin);
    }
  }

  @media (width > 1200px) {
    h3 {
      font-size: 34px;
    }
  }
}

.figure {
  margin-left: 40px;
  max-width: 100%;

  video {
    width: 100%;
    height: auto;
    display: block;
    max-width: 750px;
    border-radius: 5px;
  }
}

/* --------------------------- */
/* Cash Deposits */

.depositOptions {
  --button-radius: 5px;

  position: relative;
  border-radius: 8px;
  border: 1px solid #dfdeec;
  top: 10px;
  box-shadow: 0px 33px 54px 0px rgba(41, 33, 78, 0.05),
    0px 13.787px 22.56px 0px rgba(41, 33, 78, 0.04),
    0px 7.371px 12.062px 0px rgba(41, 33, 78, 0.03),
    0px 4.132px 6.762px 0px rgba(41, 33, 78, 0.03),
    0px 2.195px 3.591px 0px rgba(41, 33, 78, 0.02),
    0px 0.913px 1.494px 0px rgba(41, 33, 78, 0.01);
}

.cashDeposits {
  width: 100%;
  height: auto;
}

.depositInsurance,
.securityDeposit {
  position: absolute;
  top: 49.62025%;
  width: 42.7152318%;
  height: auto;
  border-radius: var(--button-radius);
  box-shadow: 0px 0px 0px 1px #dfdeec;
  background-color: #fff;
}

.securityDeposit {
  right: 5.7947%;
}

.depositInsurance {
  --active-border-width: 2px;

  left: 5.7947%;
  will-change: transform, box-shadow;
  transform: matrix(1, 0, 0, 1, 0, 0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  z-index: 10;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  @at-root :global(.tab-active) &,
    :global(.tab-hiding) & {
    transition: 0.35s cubic-bezier(0.16, 0.74, 0.41, 1.26) 0.3s;
    transition-property: transform, box-shadow;
    transform: matrix(1.3, 0, 0, 1.3, -12, -7);
    box-shadow: 0px 0px 0px var(--active-border-width) var(--purple-color),
      0px 32px 95px 0px rgba(43, 31, 73, 0.07),
      0px 13px 39px 0px rgba(43, 31, 73, 0.05),
      0px 7px 21px 0px rgba(43, 31, 73, 0.04),
      0px 4px 12px 0px rgba(43, 31, 73, 0.04),
      0px 2px 6px 0px rgba(43, 31, 73, 0.03),
      0px 0px 2px 0px rgba(43, 31, 73, 0.02);
  }
}

/* --------------------------- */
/* Rhino Deposit Insurance */

.rentersInsuranceTab {
  transform-style: flat !important;
}

.rentersInsurance {
  --shield-height: 84px;
  --shield-top: 40px;
  --pulse-size: 500px;

  max-width: 705px;
  position: relative;
  max-height: var(--figure-max-height);
  border-radius: 5px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    animation: overlayFade 2s infinite linear alternate;
    background: linear-gradient(
      180deg,
      rgba(62, 25, 167, 0.52) 0%,
      rgba(62, 25, 167, 0.52) 10.67%,
      rgba(62, 25, 167, 0.5) 21.33%,
      rgba(62, 25, 167, 0.48) 32%,
      rgba(62, 25, 167, 0.44) 42.66%,
      rgba(62, 25, 167, 0.4) 53.33%,
      rgba(62, 25, 167, 0.35) 64%,
      rgba(62, 25, 167, 0.29) 74.66%,
      rgba(62, 25, 167, 0.23) 85.33%,
      rgba(62, 25, 167, 0.17) 95.99%,
      rgba(62, 25, 167, 0.12) 106.66%,
      rgba(62, 25, 167, 0.08) 117.33%,
      rgba(62, 25, 167, 0.04) 127.99%,
      rgba(62, 25, 167, 0.02) 138.66%,
      rgba(62, 25, 167, 0) 149.32%,
      rgba(62, 25, 167, 0) 159.99%
    );
  }
}

@keyframes overlayFade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

.apartment {
  width: 100%;
  height: auto;
  display: block;
}

.shield {
  height: var(--shield-height);
  width: auto;
  position: absolute;
  top: var(--shield-top);
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  filter: drop-shadow(0px 2px 9px rgba(208, 192, 255, 0.35));
}

$pulseCount: 5;
$pulseDuration: 10s;

:export {
  pulseCount: $pulseCount;
}

.circleWrapper {
  mix-blend-mode: color-dodge;
}

.circle {
  position: absolute;
  border-radius: 50%;
  width: 110%;
  left: 50%;
  top: calc(var(--shield-top) + var(--shield-height) / 2);
  transform: translate(-50%, -50%) scale(0);
  opacity: 0.9;
  background-color: #5835bd;
  animation: pulse $pulseDuration infinite;

  &:before,
  &:after {
    content: '';
  }

  &:before {
    float: left;
    padding-top: 100%;
  }

  &:after {
    display: block;
    clear: both;
  }

  @for $i from 0 through $pulseCount {
    &:nth-child(#{$i + 1}) {
      animation-delay: $i * math.div($pulseDuration, $pulseCount);
    }
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.9;
  }

  80% {
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

/* --------------------------- */
/* Lease Guarantee */

.leaseGuarantee {
  width: 100%;
  max-width: 705px;

  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    display: block;
  }
}

/* --------------------------- */
/* Media Queries */

@media (width <= 1365px) {
  .tab {
    --figure-max-height: 410px;
  }

  .text {
    padding-top: 40px;
  }

  .depositOptions {
    top: 0;
  }

  .leaseGuarantee {
    max-width: 650px;
  }
}

@media (width <= 1320px) {
  .tab {
    --figure-max-height: 390px;
  }

  @media (width > 1160px) {
    .depositOptions {
      height: 100%;
      width: auto;
      max-height: var(--figure-max-height);
    }
  }
}

@media (width <= 1280px) {
  .text {
    padding-top: 20px;
  }
}

@media (width <= 1250px) and (width > 1160px) {
  .depositInsuranceTab {
    --text-max-width: 430px;
  }
}

@media (width <= 1190px) {
  .tab {
    --figure-max-height: 370px;
  }

  .leaseGuarantee {
    max-width: 520px;
  }
}

@media (width <= 1160px) {
  .tab {
    --figure-max-height: 350px;
  }

  .text {
    --max-width: 400px;

    padding-top: 0;
  }

  .depositOptions {
    margin-left: 30px;
    max-width: 500px;
  }
}

@media (width <= 1100px) {
  .tab {
    --figure-max-height: 300px;
  }
}

@media (width <= 1000px) {
  .text {
    --font-size: 1rem;
    --button-margin: 10px;
    --label-font-size: 16px;
    --max-width: 350px;
  }
}

@media (width <= 960px) {
  .text {
    --label-margin: 15px;
  }
}

@media (width <= 890px) {
  .wrapper {
    padding-bottom: var(--content-padding);
  }

  .tab {
    display: block;
  }

  .text {
    max-width: none;
    margin-bottom: var(--content-padding);
  }

  .figure,
  .rentersInsurance,
  .depositOptions {
    max-width: none;
  }

  .figure {
    margin: 0;
    width: 100%;

    video {
      max-width: none;
    }
  }

  .depositOptions {
    margin-left: 0;
  }

  .rentersInsurance {
    max-height: 430px;
    display: flex;
    align-items: center;
  }

  .leaseGuarantee {
    max-width: none;
  }

  .depositInsurance {
    @at-root :global(.tab-active) & {
      transform: matrix(1.2, 0, 0, 1.2, 14, -2);
    }
  }
}

@media (width <= 600px) {
  .depositOptions {
    --button-radius: 3px;
  }

  .rentersInsurance {
    --shield-height: 60px;
    --shield-top: 30px;
  }
}
