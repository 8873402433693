/* --------------------------- */
/* Partners Page Hero */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  --section-bottom-padding: 0;

  text-align: center;
}

.contentWrapper {
  overflow: hidden;

  :global(.platform-label) {
    color: var(--dark-purple-color);
    margin: 0 auto 28px auto;
    display: inline-flex;
  }
}

.text {
  --max-width: 800px;
}

.formWrapper {
  --border-radius: 5px;

  display: inline-block;
  margin: 0 auto;
  background-color: #fff;
  border-radius: var(--border-radius);
  position: relative;
  box-shadow: 0px 18.8px 33.422px 0px rgba(125, 111, 186, 0.03),
    0px 10.051px 17.869px 0px rgba(125, 111, 186, 0.04),
    0px 5.635px 10.017px 0px rgba(125, 111, 186, 0.04),
    0px 2.993px 5.32px 0px rgba(125, 111, 186, 0.05),
    0px 1.245px 2.214px 0px rgba(125, 111, 186, 0.07);
}

.form {
  --padding-x: 16px;
  --padding-y: 13px;

  display: inline-flex;
  transition: visibility step-end 0.3s, opacity 0.3s;

  @at-root .messageVisible & {
    opacity: 0;
    visibility: hidden;
  }
}

.inputWrapper {
  width: 270px;
  position: relative;
  font-size: 16px;
  flex: none;
  border-right: 3px solid var(--light-purple-bg-color);
  text-align: left;
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: #676478;
    font-weight: 600;
    padding: var(--padding-y) var(--padding-x) 0 var(--padding-x);
    line-height: 1;
    margin-bottom: calc(-1em - var(--padding-y));
    position: relative;
    top: 2px;
    z-index: 10;
    pointer-events: none;
  }

  input,
  select {
    appearance: none;
    border: none;
    background-color: transparent;
    color: var(--body-text-color);
    font-size: inherit;
    width: 100%;
    height: 100%;
    font-family: var(--font-body);
    font-weight: 500;
    flex-shrink: 0;
    outline-offset: -2px;
    // prettier-ignore
    padding:
      calc(#{app.em(12px, 16px)} + var(--padding-y) + 8px)
      var(--padding-x)
      calc(var(--padding-y) - 1px)
      var(--padding-x);

    &:focus-visible {
      outline: 2px solid var(--purple-color);
    }

    @include app.autocomplete(var(--body-text-color), #fff);
  }

  input {
    @include app.placeholder {
      color: #a8a3b7;
    }
  }

  select {
    &:invalid {
      color: #a8a3b7;
    }
  }

  &:hover,
  &:focus-within {
    label {
      color: #494756;
    }

    .arrow {
      --icon-color: #494756;
    }
  }

  &.hasError {
    label {
      color: #994646;
    }

    input,
    select {
      @include app.autocomplete(var(--body-text-color), #fff9f9);

      background-color: #fff9f9;
      outline-color: #e73f3f;

      @media (width >= 650px) {
        box-shadow: inset 0 0 0 3px #fff;
      }
    }

    input {
      @include app.placeholder {
        color: #de9b9b;
      }
    }

    select {
      &:invalid {
        color: #de9b9b;
      }
    }
  }
}

.email {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.units {
  border-radius: 0;
  padding-right: calc(var(--padding-x) + 18px);
}

.button {
  margin: 9px;
}

.arrow {
  --icon-color: #78808a;
  --icon-size: 10px;

  position: absolute;
  top: 50%;
  right: var(--padding-x);
  margin-right: 3px;
  transform: rotate(90deg);
  margin-top: calc(var(--icon-size) / -2);
}

.error {
  --icon-size: 1.02em;

  position: absolute;
  inset: 100% 3px auto 3px;
  margin-top: 12px;
  line-height: 1.3;
  font-size: 11px;
  font-weight: 600;
  color: #e73f3f;
  display: flex;
  align-items: flex-start;

  :global(.icon-sprite) {
    position: relative;
    top: 1px;
    margin-right: 4px;
  }
}

.message {
  --icon-size: #{app.em(15px, 14px)};

  position: absolute;
  inset: 0 0 auto 0;
  min-height: 100%;
  width: 100%;
  background: var(--bg-color, #fff);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  border-radius: var(--border-radius);
  opacity: 0;
  animation: fadeIn 0.3s ease-out 0.05s forwards;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  padding: 12px;

  :global(.icon-sprite) {
    margin-right: 10px;
    position: relative;
    top: 0.1em;
  }

  p {
    margin-bottom: 5px;

    &:first-child {
      margin-top: -1px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.success {
  --bg-color: #f8fff8;
  --border-color: #87cc9b;
  --text-color: #4c644f;
  --icon-color: #2b8c12;
}

.fail {
  --bg-color: #ffefef;
  --border-color: #ef9090;
  --text-color: #673c3c;
  --icon-color: #be2727;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.messageText {
  display: flex;
}

@media (width <= 1560px) {
  .wrapper {
    padding-top: 70px;
  }
}

@media (width <= 825px) {
  .formWrapper {
    --form-offset: 15px;

    width: calc(var(--form-offset) * 2 + 100%);
    margin-left: calc(var(--form-offset) * -1);
    display: block;
  }

  .form {
    display: flex;
    width: 100%;
  }

  .inputWrapper {
    flex-grow: 1;
    width: auto;
  }
}

@media (width <= 650px) {
  .formWrapper {
    --padding: 20px;

    padding: var(--padding);
    border-radius: 8px;
  }

  .form {
    display: block;
  }

  .inputWrapper {
    border-right: none;
    width: 100%;
    margin-bottom: 12px;

    input,
    select {
      border-radius: 5px;
      border: 1px solid #c7d0db;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    }

    &.hasError {
      input,
      select {
        border: 1px solid #dbacac;
      }
    }
  }

  .button {
    margin: 8px 0 0 0;
    width: 100%;
  }

  .error {
    position: static;
    margin-bottom: 8px;
  }

  .message {
    --icon-size: 30px;

    padding: var(--padding);
    background: #fff;
    border: none;
    text-align: center;
    display: block;

    :global(.icon-sprite) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  .messageText {
    background: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 20px;
    flex-direction: column;
    align-items: center;
    min-height: 100%;

    p {
      text-wrap: balance;
    }
  }
}

@media (width <= 615px) {
  .wrapper,
  .text {
    text-align: left;
  }
}

@media (width <= 500px) {
  .formWrapper {
    --form-offset: 20px;
  }
}

@media (width <= 400px) {
  .formWrapper {
    --form-offset: 12px;
  }
}
