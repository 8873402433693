/* --------------------------- */
/* Heading */
/* --------------------------- */

.center {
  text-align: center;
}

.tagline {
  display: block;
  font-family: var(--font-body);
  font-size: var(--tagline-size, 1rem);
  color: var(--tagline-color, var(--purple-color));
  font-weight: 600;
  margin-bottom: 1.2em;
  line-height: 1;

  :global(.sr) {
    position: relative;
    display: inline-block;
  }

  @media (width <= 960px) {
    font-size: var(--tagline-size, 0.9rem);
  }
}
