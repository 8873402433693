/* --------------------------- */
/* Navigation */
/* --------------------------- */

@use 'www/styles/abstracts/app';

$mobileBreakpoint: 785px;

@mixin desktop-nav {
  @media (width > $mobileBreakpoint) {
    @content;
  }
}

:export {
  mobileBreakpoint: $mobileBreakpoint;
}

/* --------------------------- */
/* Shared & Desktop Styles */

.wrapper {
  display: block;
  flex-grow: 1;
  margin-left: 55px;

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  &.resizing * {
    transition: none !important;
    animation-duration: 0s !important;
  }
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menuSection {
  --gap: 40px;

  display: flex;
  align-items: center;
}

.item {
  position: relative;
  z-index: 1000;
  margin-right: var(--gap);

  &:last-child {
    margin-right: 0;
  }
}

@include desktop-nav {
  .hasDropdown {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: -10px;
      width: var(--gap);
      pointer-events: none;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }

    &:hover,
    &:focus-within,
    &.forceShowDropdown {
      z-index: 1001;

      &:before,
      &:after {
        pointer-events: auto;
      }
    }
  }
}

.link {
  --outline-offset: 0;

  display: inline-flex;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: var(--headline-text-color);
  padding: 2px 5px;

  &:hover {
    color: var(--purple-color);

    path {
      fill: currentColor;
    }
  }

  .icon {
    --icon-size: 1em;
    --stroke-width: 2.3px;
    --icon-color: currentColor;

    display: block;
    width: 1em;
    display: block;
    margin-right: 10px;
    position: relative;
  }

  :global(.icon-landlords-stroke) {
    --stroke-width: 2.1px;

    left: 1px;
  }

  &.bold {
    font-weight: 600;
    color: var(--purple-color);

    &:hover {
      color: var(--purple-light-hover-color);
    }
  }
}

.arrow {
  width: 12px;
  height: auto;
  margin-left: 12px;
  flex: none;
}

.signInItem {
  --gap: 25px;

  margin-left: auto;
}

.subnavWrapper {
  --section-gap: 50px;
  --border-width: 7px;
  --border-radius: 12px;
  --padding-x: 40px;
  --padding-y: 40px;
  --col-px: calc(var(--padding-x) - var(--border-width));
  --col-py: calc(var(--padding-y) - var(--border-width));
  --li-my: 28px;
  --link-px: 18px;
  --link-py: 12px;
  --triangle-size: 24px;
  --sidebar-color: #fafbfc;

  @include desktop-nav {
    position: absolute;
    height: 50px;
    inset: calc(100% - 2px) 0 auto 0;
    pointer-events: none;

    @at-root {
      .item:hover &,
      .item:focus-within &,
      .forceShowDropdown & {
        pointer-events: auto;
      }
    }
  }
}

.subnavContent {
  $subnavOffset: 15px;

  visibility: hidden;
  opacity: 0;
  will-change: transform, opacity, visibility;

  @include desktop-nav {
    animation: hide 0.15s cubic-bezier(0.27, 0.06, 0, 0.86) forwards;
    position: absolute;
    inset: 0 0 auto 0;
    padding-top: 18px;

    @at-root .item:not(.hasShownDropdown) & {
      animation: none;
      transform: translateY($subnavOffset);
    }

    @keyframes hide {
      0% {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }

      98% {
        opacity: 0;
      }

      99% {
        visibility: visible;
      }

      100% {
        visibility: hidden;
        opacity: 0;
        transform: translateY(0);
      }
    }

    @keyframes show {
      0% {
        visibility: hidden;
        opacity: 0;
        transform: translateY($subnavOffset);
      }

      1% {
        opacity: 0;
        visibility: visible;
      }

      2% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }

    @at-root {
      .item:hover &,
      .item:focus-within &,
      .forceShowDropdown & {
        visibility: visible;
        animation-name: show;
        animation-duration: 0.3s;
      }
    }
  }

  &:before,
  &:after {
    content: '';
    width: var(--triangle-size);
    height: var(--triangle-size);
    border-radius: 4px;
    position: absolute;
    left: 50%;
    margin-left: calc(var(--triangle-size) / -2);
    top: 11px;
    background: #fff;
    transform: rotate(45deg);
  }

  &:before {
    z-index: -1;
    box-shadow: 3px 3px 7px rgba(14, 15, 47, 0.15);
  }

  &:after {
    z-index: 10;
  }
}

.subnav {
  // contain: content;
  background-color: #fff;
  width: max-content;
  display: flex;
  border-radius: var(--border-radius);
  border: var(--border-width) solid #fff;
  font-size: 14px;
  line-height: 1.2;
  position: relative;
  box-shadow: 0px 64px 80px 0px rgba(14, 15, 47, 0.09),
    0px 26.738px 33.422px 0px rgba(14, 15, 47, 0.06),
    0px 14.295px 17.869px 0px rgba(14, 15, 47, 0.05),
    0px 8.014px 10.017px 0px rgba(14, 15, 47, 0.05),
    0px 4.256px 5.32px 0px rgba(14, 15, 47, 0.04),
    0px 1.771px 2.214px 0px rgba(14, 15, 47, 0.03);

  &:before {
    content: '';
    position: absolute;
    inset: auto -5px calc(100% + var(--border-width)) -5px;
    height: 22px;
  }
}

.column {
  padding-left: var(--section-gap);
  padding-top: var(--col-py);
  padding-bottom: var(--col-py);
  position: relative;
  z-index: 11;
  width: fit-content;
  max-width: var(--max-width, 400px);

  &:first-child {
    padding-left: var(--col-px);
  }

  &:last-child {
    padding-right: var(--col-px);
  }

  li {
    margin-bottom: var(--li-my);
  }
}

.columnTitle {
  font-size: 13px;
  font-weight: 600;
  color: #767481;
  display: block;
  margin-bottom: 27px;
  line-height: 1.2;
  margin-top: 35px;

  &:first-child {
    margin-top: 0;
  }
}

.subnavLink {
  --outline-offset: 0;
  --icon-size: #{app.em(17px, 16px)};
  --icon-color: var(--purple-color);
  --link-mx: calc(var(--link-px) * -1);
  --link-my: calc(var(--link-py) * -1);
  --link-icon-mleft: -5px;
  --link-icon-mright: 12px;

  display: flex;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 600;
  color: var(--headline-text-color);

  @include desktop-nav {
    padding: var(--link-py) var(--link-px);
    margin: var(--link-my) var(--link-mx) calc(var(--link-my) * 2)
      var(--link-mx);
    border-radius: 8px;

    &:hover {
      background-color: #f6f9fb;

      .subnavLinkDescription {
        color: #6a6a71;
      }
    }

    @at-root .column:last-child & {
      margin-right: calc(var(--link-mx) + var(--border-width));
    }

    @at-root .column li:last-child & {
      margin-bottom: calc(var(--link-my) + 3px);
    }

    &.hasIcon {
      padding-left: calc(var(--link-px) + var(--link-icon-mleft));
      margin-left: calc(var(--link-mx) - var(--link-icon-mleft));
    }
  }
}

.subnavLinkLabel {
  display: block;
  margin-bottom: #{app.em(8px, 16px)};
  font-weight: inherit;

  &:last-child {
    margin-bottom: 0;
  }
}

.subnavLinkIcon {
  margin-right: var(--link-icon-mright);

  @include app.icon('cash') {
    y: 3%;
  }

  @include app.icon('notebook', 'demo') {
    y: 5%;
  }
}

.subnavLinkDescription {
  font-size: #{app.em(13px, 16px)};
  font-weight: 500;
  color: #84848a;
  line-height: 1.5;
  padding-bottom: 4px;
}

.overview {
  --outline-offset: 3px;
  --icon-color: var(--purple-color);

  font-size: 14px;
  color: var(--body-text-color);
  display: block;
  border-radius: 2px;

  :global(.icon-text) {
    --line-height: 1.3;
    --icon-size: #{app.em(21px, 18px)};

    color: var(--headline-text-color);
    font-size: 18px;
    margin-top: -2px;
    margin-bottom: 15px;
  }

  p {
    line-height: 1.7;
  }

  &:is(a) {
    &:hover {
      :global(.icon-text) {
        color: var(--purple-color);
      }
    }

    p {
      &:hover {
        text-decoration: underline;
        text-decoration-color: #d2c3fb;
        text-underline-offset: 2px;
      }
    }
  }

  :global(.button) {
    --button-font-size: #{app.em(13px, 14px)};
    --button-padding-x: 1.8125em;
    --button-padding-y: 1.1em;

    margin-top: 10px;
  }
}

.rentersOverview {
  --max-width: 210px;
}

.landlordsOverview {
  --max-width: 245px;
}

.landlordsProducts {
  --max-width: 300px;
}

.sidebar {
  --li-my: 20px;
  --link-px: 18px;
  --link-py: 10px;
  --radius: calc(var(--border-radius) - var(--border-width));

  background-color: var(--sidebar-color);
  padding-left: var(--padding-x);
  padding-right: var(--padding-x);
  margin-left: var(--section-gap);
  min-width: 220px;

  &:first-child {
    margin-left: 0;
    border-radius: var(--radius) 0 0 var(--radius);
  }

  &:last-child {
    padding-right: var(--padding-x);
    border-radius: 0 var(--radius) var(--radius) 0;
  }

  .subnavLink {
    --icon-size: #{app.em(15px, 14px)};
    --icon-color: #8b94a2;
    --link-icon-mleft: -4px;
    --link-icon-mright: 11px;

    font-size: 14px;
    font-weight: 500;

    @include desktop-nav {
      &:hover {
        --icon-color: #6e7684;

        background-color: #eff2f5;
      }
    }
  }

  .subnavLinkIcon {
    margin-top: 1px;
  }

  .subnavLinkDescription {
    font-size: 12px;
    color: #7c7c82;
  }
}

.account {
  --li-my: 20px;
  --link-px: 18px;
  --link-py: 10px;
  --radius: calc(var(--border-radius) - var(--border-width));

  padding: 5px 33px;
  min-width: 100px;

  &:first-child {
    margin-left: 0;
    border-radius: var(--radius) 0 0 var(--radius);
  }

  &:last-child {
    padding-right: var(--padding-x);
    border-radius: 0 var(--radius) var(--radius) 0;
  }

  .subnavLink {
    font-size: 16px;
    font-weight: 500;
  }

  .subnavContent {
    padding-top: 0;

    &:before,
    &:after {
      display: none;
    }
  }
}

@media (width <= 1340px) {
  .subnav {
    left: -50px;
  }
}

@media (width <= 1230px) {
  .menu {
    --gap: 30px;
  }

  .subnav {
    left: -100px;
  }
}

@media (width <= 1230px) and (width > $mobileBreakpoint) {
  .account {
    .subnavWrapper {
      left: auto;
      right: auto;
    }
  }
}

@media (width <= 1090px) {
  .wrapper {
    margin-left: 35px;
  }

  .menu {
    --gap: 25px;
  }

  .link {
    font-size: 16px;
  }

  .button {
    --button-font-size: 12px;
  }

  .link {
    font-size: 15px;
  }

  .arrow {
    width: 10px;
    margin-left: 10px;
  }

  .subnavWrapper {
    --section-gap: 40px;
    --padding-x: 35px;
    --padding-y: 35px;
  }

  .columnTitle {
    margin-top: 30px;
  }

  .subnavLink {
    font-size: 15px;
  }

  .landlords {
    .subnav {
      left: -150px;
    }
  }

  .landlordsProducts {
    --max-width: 270px;
  }
}

@media (width <= 1000px) {
  .renters {
    .subnav {
      left: -120px;
    }
  }

  .account {
    .subnav {
      right: -120px;
    }
  }

  .landlords {
    .subnav {
      left: -253px;
    }
  }
}

@media (width <= 860px) {
  .subnavWrapper {
    --section-gap: 35px;
  }

  .subnav {
    max-width: calc(100vw - 100px);
  }

  .overview {
    font-size: 13px;

    :global(.icon-text) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .landlordsOverview {
    --max-width: 200px;
  }
}

/* --------------------------- */
/* Mobile Styles */

.openButton,
.backButton,
.closeButton {
  @include app.disable-native-button($transparentBg: true);
}

.openButton {
  --icon-color: #4c426a;

  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -19px -16px -19px auto;

  svg {
    width: 32px;
    height: auto;
    fill: var(--icon-color);
  }

  &:hover {
    --icon-color: var(--purple-color);
  }

  @include desktop-nav {
    display: none;
  }
}

.mobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px var(--mobile-padding) 10px var(--mobile-padding);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #ebeaf9;
}

.mobileLogo,
.backButton {
  transition-property: transform, opacity, visibility;
  will-change: transform, opacity, visibility;
}

.mobileLogo {
  position: absolute;
  height: 18px;
  width: auto;
  top: 50%;
  margin-top: -9px;
  pointer-events: none;
  transition: var(--subnav-transition) var(--header-ease);
  transition-property: transform, opacity;

  @at-root .mobileSubnavOpen & {
    transform: translateX(-50px);
    opacity: 0;
  }
}

.backButton {
  --outline-offset: -5px;

  pointer-events: none;
  transform: translateX(60px);
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  color: var(--purple-color);
  padding: 18px 20px;
  margin-left: -25px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  transition-duration: 0.15s;
  transition: visibility step-end var(--subnav-transition),
    opacity var(--subnav-transition) var(--header-ease),
    transform var(--subnav-transition) var(--header-ease);

  &:active {
    opacity: 0.3 !important;
    transition-duration: 0.15s !important;
  }

  @at-root .mobileSubnavOpen & {
    pointer-events: auto;
    transform: translateX(0);
    transition-duration: var(--subnav-transition);
    opacity: 1;
    visibility: visible;
    transition: visibility step-start var(--subnav-transition),
      opacity var(--subnav-transition), transform var(--subnav-transition);
  }
}

.backArrow {
  --icon-size: 0.8em;

  transform: rotate(180deg);
  margin: -1px 8px 0 0;
}

.closeButton {
  position: relative;
  flex: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: -15px;
  transition: opacity 0.15s;

  &:active {
    opacity: 0.3;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: #505b76;
    border-radius: 5px;
    top: 50%;
    left: 50%;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.subnavButton {
  --outline-offset: -5px;
  --icon-size: #{app.em(28px, 18px)};
  --icon-color: var(--purple-color);

  @include app.disable-native-button;

  background-color: #f6f9fb;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  display: flex;
  width: 100%;
  text-align: left;
  border-top: 1px solid #dbe4eb;
  padding: var(--mobile-padding);
  color: var(--headline-text-color);

  &:active {
    background-color: #e7ecef;
  }

  @at-root li:first-child & {
    border-top: none;
  }

  .arrow {
    transform: rotate(-90deg);
    width: 15px;
    align-self: center;

    path {
      fill: #3b3a6b;
    }
  }

  .icon {
    margin: 3px 18px 0 0;
  }

  &.noDescription {
    .icon {
      margin-top: 0;
    }
  }
}

.subnavButtonText {
  flex-grow: 1;
  margin-right: var(--mobile-padding);
  align-self: center;
}

.subnavButtonTitle {
  display: block;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.subnavButtonDescription {
  font-size: 14px;
  font-weight: 500;
  color: #43414f;
  line-height: 1.5;
}

.signInLabel {
  display: block;
  color: #53557d;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
}

@include desktop-nav {
  .mobile,
  .mobileHeader,
  .signInLabel {
    display: none;
  }
}

@media (width <= $mobileBreakpoint) {
  .wrapper {
    --padding: 12px;
    --border-radius: 12px;
    --list-divider-color: #e5e5ed;
    --mobile-padding: 30px;
    --show-bg-transition: 0.25s;
    --hide-bg-transition: 0.4s;
    --show-slide-transition: 0.35s;
    --hide-slide-transition: 0.45s;
    --subnav-transition: 0.35s;
    --header-ease: ease;

    position: fixed;
    inset: 0 0 auto 0;
    height: 100%;
    padding: var(--padding);
    z-index: 9000;
    margin: 0;

    transition: visibility step-end var(--show-slide-transition),
      background-color var(--hide-bg-transition) 0.03s;
    background-color: rgba(#291561, 0);
    visibility: hidden;

    &.initializing {
      opacity: 0;
      // display: none;
    }

    &.mobileMenuOpen {
      transition: visibility step-start var(--show-slide-transition),
        background-color var(--show-bg-transition) 0s;
      visibility: visible;
      background-color: rgba(#291561, 0.85);
    }
  }

  .scrollWrapper {
    height: 100%;
    transform: translate3d(0, 100vh, 0);
    transition: transform var(--hide-slide-transition)
      cubic-bezier(0.56, 0.11, 0.15, 0.99);
    will-change: transform;
    backface-visibility: hidden;
    transform-style: preserve-3d;

    @at-root .mobileMenuOpen & {
      transform: translate3d(0, 0, 0);
      transition-duration: var(--show-slide-transition);
      // transition-timing-function: cubic-bezier(0.1, 0.55, 0.18, 1);
      transition-timing-function: cubic-bezier(0.18, 0.76, 0.31, 0.98);
    }
  }

  .nav {
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  .menuWrapper {
    background-color: #fff;
    height: 100%;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    overflow: hidden;
  }

  .menu {
    background-color: #fff;
    height: 100%;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    will-change: transform, visibility;
    transition: visibility step-start var(--subnav-transition),
      transform var(--subnav-transition);

    @at-root .mobileSubnavOpen & {
      transform: translateX(-25%);

      visibility: hidden;
      transition: visibility step-end var(--subnav-transition),
        transform var(--subnav-transition);

      pointer-events: none;
    }
  }

  .item {
    margin-right: 0;
    position: static;
    z-index: initial;
  }

  .requestDemoItem {
    display: none;
  }

  .signInItem {
    margin-left: 0;
    margin-top: auto;
    margin-bottom: env(safe-area-inset-bottom);
    text-align: center;
    padding: 12px;

    :global(.button) {
      --button-font-size: 16px;

      width: 100%;
    }
  }

  .subnavWrapper {
    position: absolute;
    background-color: #fff;
    inset: 0;
    overflow-y: auto;
    will-change: transform;
    transform: translateX(100%);
    visibility: hidden;
    transition: visibility step-end var(--subnav-transition),
      transform var(--subnav-transition);
    pointer-events: none;
    background-color: var(--sidebar-color);
    z-index: 50;

    &.subnavActive {
      transition: visibility step-start var(--subnav-transition),
        transform var(--subnav-transition);
      visibility: visible;
      transform: translateX(25%);
      pointer-events: auto;
    }
  }

  .subnavContent {
    visibility: unset;
    opacity: 1;
    position: static;
    padding: 0;
    transform: none;
    height: 100%;

    &:before,
    &:after {
      display: none;
    }
  }

  .subnav {
    position: static;
    border-radius: 0;
    width: 100%;
    max-width: none;
    box-shadow: none;
    flex-direction: column;
    border: none;
    padding: 0;
    height: 100%;

    &:before {
      display: none;
    }
  }

  .column {
    --col-px: var(--mobile-padding);

    width: 100%;
    max-width: none;
    border-radius: 0 !important;
    margin-left: 0 !important;
    padding: var(--mobile-padding);

    // &:last-child {
    //   padding-bottom: calc(var(--mobile-padding) + 10px);
    // }

    &:not(.sidebar) {
      background-color: #fff;

      + .column:not(.sidebar) {
        padding-top: 0;
      }
    }

    li {
      width: calc(100% + var(--mobile-padding) * 2);
      margin: 0 0 0 calc(var(--mobile-padding) * -1);
      position: relative;

      &:after {
        content: '';
        position: absolute;
        inset: auto 0 0 0;
        height: 1px;
        background: linear-gradient(
          90deg,
          var(--list-divider-color),
          var(--list-divider-color) 50%,
          transparent 0,
          transparent
        );
        background-size: 8px 1px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .sidebar {
    --list-divider-color: #dbdee6;

    &:last-child {
      flex-grow: 1;
    }
  }

  .subnavLink {
    padding: 15px var(--mobile-padding);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;

    @at-root .column li:first-child & {
      margin-top: -15px;
    }

    @at-root .column li:last-child & {
      margin-bottom: -15px;
    }

    &:active {
      background-color: #f3f4f8;
    }
  }

  .subnavLinkLabel {
    margin-bottom: 0;
  }

  .subnavLinkDescription {
    display: none;
  }

  .overview {
    pointer-events: none;

    p {
      max-width: 300px;
      font-size: 14px;
    }
    // font-size: 14px;

    :global(.button) {
      --button-font-size: 1.05em;
      // --button-padding-x: 1.8125em;
      --button-padding-y: 1.3em;

      pointer-events: auto;
      display: block;
      margin-top: 18px;
      margin-bottom: -2px;
    }
  }

  @media (height <= 795px) {
    .rentersOverview,
    .landlordsOverview {
      .columnTitle {
        display: none;
      }
    }
  }
}
