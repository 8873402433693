/* --------------------------- */
/* Header */
/* --------------------------- */

.wrapper {
  position: relative;
  padding-top: 50px;
  // contain: layout style;
  z-index: 1000;
}

.content {
  display: flex;
  align-items: center;
}

.logo {
  display: inline-block;
  width: 95px;
  flex: none;
  // margin-right: 55px;
  margin-top: -1px;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media (width <= 1090px) {
  .wrapper {
    padding-top: 40px;
  }

  .logo {
    width: 85px;
  }
}

@media (width <= 960px) {
  .wrapper {
    padding-top: 35px;
  }
}
