/* --------------------------- */
/* Support Section */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  background-color: var(--dark-purple-bg-color);
  color: #fff;

  h3 {
    color: inherit;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
}

.content {
  --headline-color: #fff;
  --tagline-color: var(--tagline-dark-text-color);

  flex-basis: 510px;
  margin-right: 60px;
}

.supportItemsWrapper {
  margin-top: 70px;

  h3 {
    margin-bottom: 30px;
    font-size: 25px;
  }
}

.supportItemGroup {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @at-root h3 + & {
    padding-top: 5px;
  }

  h4 {
    font-family: var(--font-body);
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 14px;
    color: var(--yellow-color);
    margin-bottom: 5px;
  }
}

.supportItems {
  --line-color: var(--dark-divider-color);

  list-style: none;
  color: #f5f0ff;
  margin-bottom: calc(var(--item-padding-y) * -1);

  li {
    padding: 4px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;

      &:after {
        display: none;
      }
    }
  }

  p {
    line-height: inherit;
    font-size: inherit;
    margin-bottom: #{app.em(6px, 14px)};

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.supportItem {
  --item-padding-x: 20px;
  --item-padding-y: 20px;

  &.hasDescription {
    --item-padding-y: 18px;
  }

  padding: var(--item-padding-y) var(--item-padding-x);
  width: calc(100% + var(--item-padding-x) * 2);
  margin-left: calc(var(--item-padding-x) * -1);
  border-radius: 6px;
  transition: all 0.1s;
  transition-property: background-color, color;
  font-size: 14px;
  line-height: inherit;
  text-align: left;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;

  @at-root button#{&} {
    @include app.disable-native-button($transparentBg: true);
  }

  @at-root {
    a#{&}:hover,
    button#{&}:hover {
      background-color: #2a2340;
      color: #fff;
    }
  }
}

.supportItemTitle {
  --border-offset: 1px;
  --line-height: 1.3;
  --icon-size: #{app.em(19px, 20px)};
  --leading-height: calc(1em * var(--line-height));

  display: flex;
  font-size: 20px;
  font-weight: 500;
  line-height: var(--line-height);
  color: #fff;
  margin-top: -1px;

  :global {
    .icon-sprite {
      --center-offset: calc(var(--leading-height) / 2 - var(--icon-size) / 2);

      position: relative;
      margin-right: #{app.em(12px, 20px)};
      margin-top: calc(var(--center-offset) + var(--icon-offset, 0px));
    }

    .icon-notebook {
      top: 1px;
    }

    .icon-blockquote {
      top: -1px;
    }
  }

  + p {
    margin-top: 5px;
  }
}

.supportItemTitleText {
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}

.arrow {
  --icon-size: 12px;
  --icon-color: currentColor;

  margin-left: 10px;
  transition: color 0.1s;
  color: #8d8997;

  @at-root .supportItem:hover & {
    color: #cbc9d2;
  }
}

.onlineStatus {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  padding: 4px 11px 4px 8px;
  border-radius: 15px;
  align-self: center;
  line-height: 1;
  font-weight: 700;
  color: #918d9c;
  background-color: #393052;

  &:before {
    content: '';
    display: block;
    width: #{app.em(6px, 11px)};
    height: #{app.em(6px, 11px)};
    border-radius: 50%;
    margin-right: 6px;
    background-color: #645e74;
  }

  &.online {
    // color: #2c843d;
    color: #2d7b3d;
    background: #e7ffec;

    &:before {
      background-color: #38c152;
    }
  }
}

.faqs {
  flex: 1;
  max-width: 715px;

  h3 {
    margin-bottom: 40px;

    // On desktop heading is only for screen readers
    @media (width > 850px) {
      visibility: hidden;
      position: absolute;
      width: 0px;
      height: 0px;
    }
  }
}

.faqLink {
  --button-text-color: #fff;

  margin-top: 30px;
}

@media (width <= 1100px) {
  .content {
    flex-basis: 430px;
    margin-right: 45px;
  }

  .supportItemsWrapper {
    margin-top: 50px;
  }
}

@media (width <= 930px) {
  .content {
    flex-basis: 380px;
    margin-right: 40px;
  }
}

@media (width <= 850px) {
  .contentWrapper {
    display: block;
  }

  .content {
    margin-right: 0;
    margin-bottom: 50px;
  }
}
