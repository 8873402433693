/* --------------------------- */
/* Alerts */
/* --------------------------- */

.wrapper {
  padding: 1.5em;
  border-radius: 7px;
  display: flex;
  align-items: center;
  background-color: var(--background-color, #f7f7f7);
  color: var(--color, var(--body-text-color));
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

.error {
  --background-color: #fde8e8;
  --color: #c22e36;
}

.success {
  --background-color: #e6f4ea;
  --color: #348034;
}

.warning {
  --background-color: #fff3cd;
  --color: #6d5308;
}

.info {
  --background-color: #d9edf7;
  --color: #31708f;
}
