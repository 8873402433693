/* --------------------------- */
/* Lottie Animation */
/* --------------------------- */

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;

  svg {
    width: 100%;
    height: auto !important;

    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &.inline {
    contain: content;
  }

  &:not(.inline) {
    contain: strict;
    content-visibility: auto;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.animation {
  width: 100%;
  position: relative;

  &.hidden {
    visibility: hidden;
  }
}

.fonts {
  position: absolute;
  pointer-events: none;
  clip: rect(0, 0, 0, 0);
}
