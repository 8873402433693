/* --------------------------- */
/* Tabs */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  @at-root :global(.section-top) + & {
    @media (width > 1100px) {
      padding-top: 10px;
    }
  }
}

@mixin scrollable-styles {
  border-top: 1px solid #e5e4f3;
  border-bottom: 1px solid #e5e4f3;
  box-shadow: 0 1px 4px -4px rgba(#000, 0.9);
  overflow: hidden;

  .buttonsScroller {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: 40px;
    padding: var(--scroll-py) var(--scroll-px)
      calc(var(--scroll-py) + var(--scrollbar-offset)) var(--scroll-px);
    margin-bottom: calc(var(--scrollbar-offset) * -1);
  }
}

.buttonsWrapper {
  --scroll-px: 12px;
  --scroll-py: 12px;
  --scrollbar-offset: 18px; // Hide scrollbar

  width: calc(100% + var(--content-padding) * 2);
  margin-left: calc(var(--content-padding) * -1);
  position: relative;

  &.scrollable {
    @include scrollable-styles;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 85px;
      z-index: 10;
      pointer-events: none;
      background: linear-gradient(
        90deg,
        rgba(#fff, 0.9) 0%,
        rgba(#fff, 0.89) 6.67%,
        rgba(#fff, 0.87) 13.33%,
        rgba(#fff, 0.83) 20%,
        rgba(#fff, 0.77) 26.67%,
        rgba(#fff, 0.69) 33.33%,
        rgba(#fff, 0.6) 40%,
        rgba(#fff, 0.5) 46.67%,
        rgba(#fff, 0.4) 53.33%,
        rgba(#fff, 0.3) 60%,
        rgba(#fff, 0.21) 66.67%,
        rgba(#fff, 0.13) 73.33%,
        rgba(#fff, 0.07) 80%,
        rgba(#fff, 0.03) 86.67%,
        rgba(#fff, 0.01) 93.33%,
        rgba(#fff, 0) 100%
      );
    }

    &:before {
      left: 0;
      opacity: var(--left-shadow-opacity, 0);
    }

    &:after {
      right: 0;
      transform: rotate(180deg);
      opacity: var(--right-shadow-opacity, 0);
    }
  }
}

.sentinel {
  position: absolute;
  top: 0;
  height: 1px;
  width: 60px;
  visibility: hidden;
  pointer-events: none;

  &.left {
    left: 30px;
  }

  &.right {
    right: calc(var(--scroll-px) * -1);
    margin-right: 1px;
  }
}

.buttons {
  --gap: 16px;

  display: flex;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
  position: relative;
}

.button {
  @include app.disable-native-button;

  flex: none;
  text-align: center;
  font-weight: 600;
  color: #282736;
  background-color: #ededee;
  padding: 13px 32px;
  font-size: 17px;
  border-radius: 3em;
  margin-right: var(--gap);

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: #e7e7e7;
  }

  &:global(.button-active) {
    color: #fff;
    background-color: var(--purple-color);
  }
}

.content {
  margin-top: 95px;
  position: relative;

  @at-root .scrollable + & {
    margin-top: var(--content-padding);
  }
}

.tab {
  --transition: 0.3s;
  --active-delay: 0.14s;

  opacity: 0;
  position: absolute;
  inset: 0 0 auto 0;
  visibility: hidden;
  transition: visibility step-end var(--transition), opacity var(--transition);
  transform-style: preserve-3d;

  &:global(.tab-active) {
    opacity: 1;
    visibility: visible;
    position: relative;
    z-index: 10;
    transition: visibility step-start var(--transition) var(--active-delay),
      opacity var(--transition) var(--active-delay);
  }
}

@media (width <= 1560px) {
  .content {
    margin-top: var(--section-padding);
  }
}

@media (width <= 1110px) {
  .buttons {
    --gap: 12px;
  }

  .button {
    padding: 12px 22px;
    font-size: 14px;
  }
}

// On smaller screen sizes, hide buttons
// until we determine if they are scrollable
@media (width <= 920px) {
  .buttonsWrapper {
    &.hidden {
      visibility: hidden;
    }
  }
}

@media (width <= 800px) {
  .buttonsWrapper {
    @include scrollable-styles;
  }

  .buttons {
    --gap: 12px;
  }

  .button {
    border-radius: 6px;
    padding: 14px 20px;
  }
}
