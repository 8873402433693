/* --------------------------- */
/* Home Page Hero */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  --angle-height: 100px;
  --button-gap: 15px;
  --animation-border-radius: 15px;
  --browser-button-offset: 35px;

  padding: 70px 0 0 0;
}

.angles {
  height: var(--angle-height);
  background-color: #fff;
  position: absolute;
  inset: auto 0 0 0;
  clip-path: polygon(0 0, 50% 100%, 100% 0, 100% 100%, 0 100%);
  margin-bottom: -1px;
  z-index: 10;
}

.contentWrapper {
  overflow: hidden;
  padding-top: 10px;
  margin-top: -10px;
}

.content {
  display: flex;
}

.intro {
  flex: 1;
  padding: 60px 80px 120px 0;
  margin-bottom: var(--angle-height);
}

.text {
  font-size: 16px;

  p {
    max-width: 550px;
    // text-wrap: balance;
  }

  .title {
    font-size: #{app.em(19px, 16px)};
    font-weight: 600;
    line-height: 1.6;
  }
}

.reviews {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  padding: 14px 17px 14px 20px;
  margin: 30px 0 35px;
  background-color: rgba(#fff, 0.67);
  border: 1px solid #c0b7db;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(#000, 0.07);
  font-size: 13px;

  svg {
    flex: none;
  }
}

.reviewsIcon {
  --icon-size: #{app.em(14px, 13px, 7)};

  color: #bcb4d1;
  margin-right: 15px;
}

.stars {
  margin-right: 13px;
  flex: none;
}

.reviewsItem {
  line-height: 1;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;

  em {
    font-style: normal;
    color: var(--purple-color);
    font-weight: 600;
    font-size: 15px;
    font-family: var(--font-headline);
    // margin-right: 5px;
    margin: 1px 5px 1px 0;
  }

  span {
    margin-top: -1px;
  }

  + .reviewsItem {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #cabee9;

    @include app.retina {
      border-left-width: 1.5px;
    }
  }
}

.divider {
  max-width: 555px;
  margin-bottom: 33px;
}

.buttons {
  margin-bottom: -12px;

  :global(.button) {
    margin-right: 15px;
    margin-bottom: 12px;

    &:last-child {
      margin-right: 0;
      // margin-bottom: 0;
    }
  }
}

.animation {
  width: 720px;
  flex: none;
  margin-right: -50px;
}

@media (width <= 1570px) {
  .animation {
    width: 650px;
    margin-right: 0;
  }
}

@media (width <= 1300px) {
  .wrapper {
    padding-top: 50px;
  }

  .intro {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .animation {
    width: 600px;
  }
}

@media (width <= 1130px) {
  .wrapper {
    --angle-height: 70px;
    --browser-button-offset: 30px;
    --browser-button-size: 8px;
  }

  .intro {
    width: 430px;
    flex: none;
    padding-top: 0;
    padding-bottom: 60px;
  }

  .animation {
    width: 100%;
    flex: 1;
  }
}

@media (width <= 1125px) {
  .wrapper {
    --browser-button-offset: 35px;
    --browser-button-size: 9px;

    padding-top: 60px;
  }

  .content {
    display: block;
  }

  .intro,
  .text {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .intro {
    padding: 0;
    width: 100%;
  }

  .animation {
    height: 550px;
    max-width: 880px;
    margin: 0 auto;

    :global(.animation-wrapper) {
      &:before,
      &:after {
        content: '';
        position: absolute;
        bottom: -45px;
        width: 60%;
        height: 100px;
        opacity: 0.2;
        z-index: 1;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 6.67%,
          rgba(0, 0, 0, 0.01) 13.33%,
          rgba(0, 0, 0, 0.03) 20%,
          rgba(0, 0, 0, 0.05) 26.67%,
          rgba(0, 0, 0, 0.08) 33.33%,
          rgba(0, 0, 0, 0.12) 40%,
          rgba(0, 0, 0, 0.16) 46.67%,
          rgba(0, 0, 0, 0.2) 53.33%,
          rgba(0, 0, 0, 0.24) 60%,
          rgba(0, 0, 0, 0.28) 66.67%,
          rgba(0, 0, 0, 0.31) 73.33%,
          rgba(0, 0, 0, 0.33) 80%,
          rgba(0, 0, 0, 0.35) 86.67%,
          rgba(0, 0, 0, 0.36) 93.33%,
          rgba(0, 0, 0, 0.36) 100%
        );
      }

      &:before {
        left: -10px;
        transform: rotate(7.5deg);
      }

      &:after {
        right: -10px;
        transform: rotate(-7.5deg);
      }
    }
  }
}

@media (width <= 960px) {
  .divider {
    max-width: 531px;
  }
}

@media (width <= 880px) {
  .intro {
    margin-bottom: 50px;
  }
}

@media (width <= 750px) {
  .animation {
    height: 450px;
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}

@media (width <= 615px) {
  .wrapper {
    --angle-height: 50px;
    --browser-button-offset: 25px;
    --browser-button-size: 7px;
  }

  .intro,
  .text {
    text-align: left;
    display: block;
  }

  .text {
    p {
      max-width: none;
    }
  }

  .reviews {
    margin-bottom: 33px;
  }

  .buttons {
    // margin-bottom:
    :global(.button) {
      width: 100%;
      margin-right: 0;
      // margin-bottom: 12px;
    }
  }

  .animation {
    width: calc(100% + 20px);
    margin-left: -10px;

    :global(.animation-wrapper) {
      &:before {
        left: -15px;
        transform: rotate(10.5deg);
      }

      &:after {
        right: -15px;
        transform: rotate(-10.5deg);
      }
    }
  }
}

@media (width <= 460px) {
  .intro {
    margin-bottom: 40px;
  }

  .reviews {
    padding-left: 17px;
    width: 100%;
  }

  .reviewsIcon {
    margin-right: 11px;
  }

  .stars {
    margin-right: 10px;
  }

  .animation {
    height: 400px;
  }
}

@media (width <= 420px) {
  .animation {
    height: 360px;
    width: calc(100% + 36px);
    margin-left: -18px;
  }
}

@media (width <= 412px) {
  .reviewsItem {
    + .reviewsItem {
      padding-left: 0;
      margin-left: 12px;
      border-left: none;
    }
  }
}

@media (width <= 400px) {
  .reviewsIcon {
    display: none;
  }
}

@media (width <= 385px) {
  .reviews {
    --star-size: 11px;
  }
}

@media (width <= 375px) {
  .reviewsItem {
    em {
      font-size: 13px;
    }
  }
}
