/* --------------------------- */
/* Reviews */
/* --------------------------- */

.review {
  --star-size: 25px;
  --active-star-color: #fdb52d;
  --inactive-star-color: #e2dfe8;

  border-radius: 6px;
  border: 1px solid #eaeaf4;
  padding: 35px;
  box-shadow: 0 1px 4px rgba(#000, 0.04), 0 4px 8px rgba(#000, 0.04);
  text-align: left;
  display: inline-block;
  font-size: 15px;
  width: fit-content;
  max-width: 570px;
}

.stars {
  margin-bottom: 22px;
}

.name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 11px;
  display: block;
}

.carousel {
  --item-spacing: 46px;

  @at-root h2 + & {
    margin-top: 70px;
  }
}

@media (width <= 1000px) {
  .carousel {
    --item-spacing: 25px;

    display: flex;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    padding: 0 0 60px calc(var(--item-spacing) * 2);
    margin-bottom: -20px;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    @at-root h2 + & {
      margin-top: 40px;
    }
  }

  .review {
    flex: none;
    margin-right: var(--item-spacing);
    max-width: calc(100vw - var(--item-spacing) * 4);
    scroll-snap-align: center;
    scroll-padding: 20px;

    &:last-child {
      margin-right: calc(var(--item-spacing) * 2);
    }
  }
}

@media (width <= 630px) {
  .carousel {
    padding-bottom: 40px;

    @at-root h2 + & {
      margin-top: 30px;
    }
  }
}

@media (width <= 530px) {
  .review {
    padding: 28px;
  }
}
