/* --------------------------- */
/* Partners Page Hero Animation */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  --border-radius: 22px;
  --border-width: 9px;
  --border-color: #e0e0e0;
  --col-padding: 30px;
  --top-bar-padding: 20px;

  max-width: 1250px;
  height: 400px;
  contain: strict;
  margin: 70px auto 0 auto;
  background-color: #fff;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  border: var(--border-width) solid #fff;
  border-bottom: none;
  cursor: default;
  user-select: none;
  text-align: left;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  color: var(--body-text-color);
  font-size: 16px;
  box-shadow: 0px 2.75px 11.127px 0px rgba(34, 21, 55, 0.01),
    0px 6.608px 26.74px 0px rgba(34, 21, 55, 0.02),
    0px 12.441px 50.349px 0px rgba(34, 21, 55, 0.03),
    0px 22.193px 89.813px 0px rgba(34, 21, 55, 0.03),
    0px 41.51px 167.986px 0px rgba(34, 21, 55, 0.04),
    0px 99.359px 402.095px 0px rgba(34, 21, 55, 0.05);
}

.navWrapper {
  background-color: var(--light-purple-bg-color);
  border-right: 1px solid var(--border-color);
  padding: 35px;
  flex: none;
}

.logo {
  width: app.em(85px, 16px);
  height: auto;
  display: block;
  margin-bottom: app.em(30px, 16px);
}

.nav {
  list-style: none;
  font-family: var(--font-headline);
  color: var(--headline-text-color);

  li {
    margin-bottom: 15px;

    &:first-child {
      font-weight: 700;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.activityFeedWrapper {
  background-color: var(--light-purple-bg-color);
  border-right: 1px solid var(--border-color);
  flex-basis: 500px;
  flex-shrink: 0;
  line-height: 1.1;
}

.search,
.overviewTitle {
  padding: var(--top-bar-padding) var(--col-padding);
  border-bottom: 1px solid var(--border-color);
  font-size: app.em(14px, 16px);
  line-height: 1;
  position: relative;
  z-index: 10;
}

.search {
  background-color: var(--light-purple-bg-color);
}

.activityFeed {
  padding: var(--col-padding);
}

.activityFeedTitle {
  font-family: var(--font-headline);
  color: var(--headline-text-color);
  font-weight: 600;
  font-size: app.em(20px, 16px);
  margin-bottom: app.em(25px, 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:after {
    content: '20';
    line-height: 1;
    color: #fff;
    background-color: #000;
    border-radius: 30px;
    margin: 0 -3px 0 10px;
    font-weight: 400;
    font-size: app.em(13px, 16px);
    // prettier-ignore
    padding:
      #{app.em(5px, 13px)}
      #{app.em(10px, 13px)}
      #{app.em(6px, 13px)}
      #{app.em(10px, 13px)};
  }
}

.activityItem {
  background-color: #fff;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 12px;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0;
  }
}

.activityContent {
  font-size: app.em(13px, 16px);
  padding: app.em(17px, 13px);
}

.activityTitle {
  display: flex;
  justify-content: space-between;
  font-family: var(--font-headline);
  color: var(--headline-text-color);
  line-height: 1.1;
  font-size: app.em(16px, 13px);
  margin-bottom: app.em(20px, 16px);
}

.activityRow {
  display: flex;
  align-items: flex-start;

  span {
    &:last-child {
      margin-left: auto;
    }
  }
}

.coverage {
  margin-bottom: 22px;

  &:before {
    content: 'Coverage';
    padding-right: 10px;
    margin-right: 10px;
    color: #7a7a7a;
    font-size: app.em(10px, 13px);
    border-right: 1px solid #e9e9e9;
  }
}

.activityStatus {
  line-height: 1;
  padding: 3px 8px;
  font-size: app.em(12px, 16px);
  position: relative;
  top: -3px;
  display: block;
  border-radius: 30px;
  color: #595959;
  background-color: #f5f5f5;
}

.activityAlert {
  padding: 15px 17px 9px 17px;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #fdf9f6;
  color: #e05e02;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: app.em(13px, 16px);

  span {
    margin-bottom: 6px;

    &:last-child {
      font-size: app.em(11px, 16px);
      margin-top: 1px;
    }
  }

  &.error {
    background-color: #fdf7f7;
    color: #df2d42;
  }
}

.mobileAlertText {
  display: none;
}

.overviewWrapper {
  flex-grow: 1;
}

.overviewTitle {
  font-family: var(--font-headline);
  border-top: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-top-right-radius: calc(var(--border-radius) - var(--border-width));
  padding-top: calc(var(--top-bar-padding) - 1px);
  font-weight: 600;
  background-color: #fff;
}

.overviewContent {
  padding: var(--col-padding);

  > * {
    margin-bottom: app.em(28px, 16px);

    &:first-child {
      margin-top: app.em(10px, 16px);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.goal {
  position: relative;
  background-color: #82828b;
  color: #fff;
  border-radius: 100px;
  overflow: hidden;
  line-height: 1.2;
  padding: #{app.em(22px, 16px)} #{app.em(40px, 16px)};

  &:before {
    content: '';
    position: absolute;
    inset: 0 auto 0 0;
    width: 15%;
    background-color: var(--headline-text-color);
  }

  span {
    position: relative;
    z-index: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-headline);

    &:before {
      content: 'Monthly subs goal';
      font-size: app.em(14px, 16px);
      font-weight: 600;
      margin-right: 10px;
    }

    &:after {
      content: '60/898';
      font-size: app.em(30px, 16px);
      letter-spacing: -0.01em;
    }
  }
}

.overviewBox {
  border: 1px solid var(--headline-text-color);
  font-size: app.em(14px, 16px);
  padding: app.em(30px, 14px);
  border-radius: app.em(18px, 14px);
  line-height: 1.4;
}

.overviewBoxTitle {
  font-family: var(--font-headline);
  color: var(--headline-text-color);
  font-weight: 600;
  font-size: app.em(19px, 14px);
  display: block;
  margin-bottom: app.em(18px, 19px);
  line-height: 1.15;
  text-wrap: pretty;
}

.overviewBoxDetails {
  font-size: app.em(40px, 14px);
  margin-top: app.em(27px, 40px);
  padding-top: app.em(27px, 40px);
  margin-bottom: -2px;
  font-weight: 600;
  font-family: var(--font-headline);
  color: var(--headline-text-color);
  border-top: 1px solid var(--headline-text-color);
  line-height: 1;
}

/* --------------------------- */
/* Animations */

.activityFeed,
.overviewContent {
  will-change: transform;
  contain: content;
  transform: translate3d(0, 0, 0);
  animation: scroll var(--duration) ease-in-out alternate infinite
    var(--delay, 0s);

  @at-root .paused & {
    animation-play-state: paused;
  }
}

.activityFeed {
  --final-y: translate3d(0, -62%, 0);
  --duration: 15s;
  --delay: 0.5s;
}

.overviewContent {
  --final-y: translate3d(0, -45%, 0);
  --duration: 10s;
  --delay: 10s;
}

@keyframes scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }

  8% {
    transform: translate3d(0, 0, 0);
  }

  92% {
    transform: var(--final-y);
  }

  100% {
    transform: var(--final-y);
  }
}

@media (width <= 1250px) {
  .wrapper {
    --col-padding: 25px;
  }

  .goal {
    span {
      &:before {
        font-size: 12px;
      }

      &:after {
        font-size: 25px;
      }
    }
  }

  .overviewContent {
    --final-y: translate3d(0, -50%, 0);
  }
}

@media (width <= 1250px) {
  .activityFeedWrapper {
    flex-basis: 410px;
  }
}

@media (width <= 1060px) {
  .wrapper {
    --col-padding: 20px;
    --top-bar-padding: 15px;

    font-size: 13px;
    height: 350px;
  }

  .navWrapper {
    padding: 25px;
  }
}

@media (width <= 1020px) {
  .wrapper {
    margin-top: 60px;
  }

  .navWrapper {
    padding: 20px;
    font-size: app.em(11px, 13px);
  }

  .activityFeedWrapper {
    flex-basis: 345px;
  }
}

@media (width <= 910px) {
  .wrapper {
    --wrapper-offset: 15px;

    width: calc(var(--wrapper-offset) * 2 + 100%);
    margin-left: calc(var(--wrapper-offset) * -1);
  }

  .navWrapper {
    display: none;
  }
}

@media (width <= 910px) {
  .activityFeedWrapper {
    flex-basis: 300px;
  }

  .coverage {
    flex-wrap: wrap;

    &:before {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
      width: 100%;
      margin-bottom: 10px;
      font-size: app.em(12px, 13px);
    }
  }

  .goal {
    span {
      &:before {
        font-size: app.em(10px, 13px);
      }

      &:after {
        font-size: app.em(20px, 13px);
      }
    }
  }

  .overviewBoxDetails {
    font-size: app.em(30px, 13px);
  }
}

@media (width <= 700px) {
  .wrapper {
    height: 300px;
    margin-top: 40px;
  }
}

@media (width <= 650px) {
  .wrapper {
    margin-top: 30px;
  }
}

@media (width <= 630px) {
  .wrapper {
    --col-padding: 18px;

    font-size: 11px;
  }

  .activityAlert {
    padding: 10px 12px 4px 12px;
    margin-top: 2px;
  }

  .coverage {
    margin-bottom: 10px;
  }

  .goal {
    padding-left: app.em(15px, 11px);
    padding-right: app.em(15px, 11px);
  }

  .overviewBox {
    padding: app.em(15px, 11px);
  }
}

@media (width <= 600px) {
  .activityFeedWrapper {
    flex-basis: 250px;
  }

  .goal {
    span {
      &:before {
        content: 'Monthly subs';
      }
    }
  }
}

@media (width <= 520px) {
  .wrapper {
    --col-padding: 15px;

    font-size: 10px;
  }
}

@media (width <= 500px) {
  .wrapper {
    --col-padding: 12px;
    --wrapper-offset: 20px;
  }

  .activityFeedWrapper {
    flex-basis: 210px;
  }

  .desktopAlertText {
    display: none;
  }

  .mobileAlertText {
    display: block;
  }

  .coverage {
    display: block;

    &:before {
      margin-bottom: 0.5em;
      display: block;
    }

    span {
      display: block;
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (width <= 400px) {
  .wrapper {
    --wrapper-offset: 12px;
    --col-padding: 10px;
  }

  .activityFeedWrapper {
    flex-basis: 50%;
  }

  .activityContent {
    padding: 5px;
  }

  .overviewWrapper {
    flex-grow: 0;
    flex-basis: 50%;
  }
}

@media (max-width: 615px) {
  .wrapper {
    --border-radius: 18px;
    --border-width: 7px;
  }
}
