/* --------------------------- */
/* Intro Section */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  --section-top-padding-offset: 20px;
}

.line {
  display: block;
}

@media (width <= 720px) {
  .wrapper {
    text-align: left;
  }

  .line {
    margin-bottom: 15px;
  }
}

@media (width <= 510px) {
  .line {
    margin-bottom: 20px;
  }
}
