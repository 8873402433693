/* --------------------------- */
/* Infinite Scrolling Carousel */
/* --------------------------- */

.wrapper {
  // Add extra top/bottom space to ensure
  // items with shadows don't get cut off
  --overflow-offset: var(--shadow-size, 30px);

  position: relative;

  &.fadeOut {
    --default-fade-width: 330px;
    --color: var(--fade-color, var(--section-background-color, #fff));

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: var(--fade-width, var(--default-fade-width));
      max-width: 25%;
      pointer-events: none;
      z-index: 10;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        var(--color) 7.96%,
        color-mix(in srgb, var(--color) 99.1353%, transparent) 14.1%,
        color-mix(in srgb, var(--color) 96.449%, transparent) 20.23%,
        color-mix(in srgb, var(--color) 91.834%, transparent) 26.37%,
        color-mix(in srgb, var(--color) 85.2589%, transparent) 32.5%,
        color-mix(in srgb, var(--color) 76.8225%, transparent) 38.64%,
        color-mix(in srgb, var(--color) 66.8116%, transparent) 44.78%,
        color-mix(in srgb, var(--color) 55.7309%, transparent) 50.91%,
        color-mix(in srgb, var(--color) 44.2691%, transparent) 57.05%,
        color-mix(in srgb, var(--color) 33.1884%, transparent) 63.18%,
        color-mix(in srgb, var(--color) 23.1775%, transparent) 69.32%,
        color-mix(in srgb, var(--color) 14.7411%, transparent) 75.46%,
        color-mix(in srgb, var(--color) 8.1659899%, transparent) 81.59%,
        color-mix(in srgb, var(--color) 3.551%, transparent) 87.73%,
        color-mix(in srgb, var(--color) 0.86472%, transparent) 93.86%,
        color-mix(in srgb, var(--color) 0.00001%, transparent) 100%
      );

      @media (width <= 550px) {
        display: none;
      }
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
      transform: rotate(180deg);
    }
  }
}

.scrollWrapper {
  position: relative;
  overflow: hidden;
  contain: content;
  padding: var(--overflow-offset) 0;
  margin: calc(var(--overflow-offset) * -1) 0;
}

.scroller {
  position: relative;
  // display: inline-flex;
  display: flex;
  width: max-content;
  cursor: default;

  &.hidden {
    visibility: hidden;
  }
}

.itemWrapper {
  --padding: calc(var(--item-spacing, 30px) / 2);

  position: relative;
  padding-left: var(--padding);
  padding-right: var(--padding);
}
