/* --------------------------- */
/* Accordion */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  --padding: 35px;
  --border-radius: 6px;

  background-color: #f8f6fe;
  border-radius: var(--border-radius);
  margin-bottom: 15px;
  color: var(--body-text-color);
  font-size: 15px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  &.expanded {
    background-color: #fff;
  }
}

.title {
  --line-height: 1.35;

  @include app.disable-native-button(
    $transparentBg: true,
    $fontFamily: var(--font-headline)
  );

  color: var(--headline-text-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  padding: calc(var(--padding) - 0.35em) var(--padding) 0 var(--padding);
  font-size: 21px;
  line-height: var(--line-height);
  font-weight: 600;
  border-radius: calc(var(--border-radius) + 2px);
  position: relative;

  &:focus-visible {
    outline-offset: -6px;
  }

  @at-root .noHover & {
    background-color: transparent !important;
  }

  &:hover {
    background-color: #fff;
  }

  @at-root .expanded & {
    &:hover {
      background-color: #f8f6fe;
    }
  }
}

.titleText {
  padding-bottom: calc(var(--padding) - 0.35em);
  flex: 1;
  display: block;

  @at-root .wrapper:not(.expanded) & {
    &:after {
      display: none;
    }
  }
}

.toggleIcon {
  --icon-size: 22px;
  --leading-height: calc(1em * var(--line-height));
  --margin-top: calc(var(--leading-height) / 2 - var(--icon-size) / 2);

  margin: var(--margin-top) -6px 0 var(--padding);
  position: relative;
  width: 22px;
  height: 22px;
  display: block;
  flex: none;
  border: 2px solid var(--purple-color);
  border-radius: 4px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: var(--purple-color);
    inset: 50% 4px auto 4px;
    transform: translateY(-50%);
    transition: transform 0.5s cubic-bezier(0.16, 0.99, 0.67, 1), opacity 0.25s;
    border-radius: 2px;
  }

  &:after {
    transform: translateY(-50%) rotate(90deg);
  }

  @at-root .expanded & {
    &:before {
      transform: translateY(-50%) rotate(180deg);
      opacity: 0;
    }

    &:after {
      transform: translateY(-50%) rotate(360deg);
    }
  }
}

.content {
  display: none;
  margin: 0 var(--padding);
  padding: calc(var(--padding) - 0.4em) 0 var(--padding) 0;

  @at-root .expanded & {
    display: block;
  }
}

.link {
  --button-font-size: 17px;
  --button-text-color: var(--dark-purple-color);

  margin-top: 18px;
}

@media (width <= 1100px) {
  .wrapper {
    --padding: 27px;
  }

  .link {
    margin-top: 8px;
  }
}
