/* --------------------------- */
/* Partner Stats */
/* --------------------------- */

.wrapper {
  --bg-color: #fff;

  :global(.section-top) {
    p {
      max-width: 920px;
    }
  }
}
