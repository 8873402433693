/* --------------------------- */
/* Team Use Cases */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  background-color: var(--medium-gray-bg-color);
}

.content {
  --border-width: 10px;
  --border-radius: 17px;

  background-color: #fff;
  display: flex;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 0 0 var(--border-width) #fff;
  margin: 0 var(--border-width);
}

.tabs {
  --icon-spacing: 17px;
  --icon-color: #93939a;
  --line-color: #b2b4bf;
  --padding-y: 30px;
  --padding-x: 50px;

  background-color: #f8f9fb;
  list-style: none;
  width: 560px;
  flex-shrink: 0;
  z-index: 10;
  padding: 30px 0;

  li {
    &:after {
      left: var(--padding-x);
      right: var(--padding-x);
      z-index: 10;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.tab {
  @include app.disable-native-button($transparentBg: true);

  font-size: 18px;
  width: 100%;
  padding: var(--padding-y) var(--padding-x);
  text-align: left;

  &:hover {
    --icon-color: #787883;

    background-color: #f3f4f6;
  }

  &:focus-visible {
    outline-offset: -5px;
  }

  &.active {
    --icon-color: var(--purple-color);

    color: var(--purple-color);
    background-color: #fff;
  }
}

.details {
  flex: 1;
  line-height: 1.9;
  display: flex;
  width: 100%;
  overflow: hidden;

  h3 {
    margin-bottom: 1em;
  }
}

.detailsText {
  display: none;
  visibility: hidden;
  padding: 60px 80px 80px 80px;
  font-size: 17px;
  width: 100%;
  flex: none;
  order: 1;

  &.active {
    display: block;
    visibility: visible;
    order: 0;
  }

  @at-root .normalizeHeight & {
    display: block;
  }
}

.detailsIcon {
  --icon-color: var(--purple-color);
  --icon-size: 40px;

  margin-bottom: 10px;
}

.mobileTabsWrapper {
  display: none;
  // border: 1px solid red;
  position: relative;
  font-size: 15px;
  position: sticky;
  top: 0;
  background-color: var(--medium-gray-bg-color);
}

.mobileTabIcon {
  --icon-size: 1.2em;
  --icon-color: var(--purple-color);

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 22px;
  pointer-events: none;
}

.mobileTabSelect {
  appearance: none;
  display: block;
  border: none;
  background-color: #fff;
  width: 100%;
  padding: 20px 40px 20px calc(1.2em + 36px);
  font-family: var(--font-body);
  color: var(--headline-text-color);
  font-weight: 600;
  font-size: inherit;
  cursor: pointer;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  box-shadow: 0 1px 6px -6px rgba(#000, 0.7);
  outline: none;
  white-space: normal;
  line-height: 1.1;

  &:hover {
    background-color: #fafafd;
  }
}

.mobileTabSelectArrow {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 12px;
  height: auto;
  pointer-events: none;
}

@media (width <= 1400px) {
  .tabs {
    width: 450px;
  }
}

@media (width <= 1300px) {
  .detailsText {
    padding: 45px 60px 60px 60px;
  }
}

@media (width <= 1230px) {
  .tabs {
    --padding-x: 40px;

    width: 400px;
  }
}

@media (width <= 1100px) {
  .details {
    h3 {
      font-size: 26px;
      margin-bottom: 0.75em;
    }
  }

  .detailsText {
    padding: 40px 50px 50px 50px;
    font-size: 16px;
  }
}

@media (width <= 1000px) {
  .tabs {
    --padding-x: 30px;
    --padding-y: 25px;

    width: 350px;
  }

  .tab {
    font-size: 16px;
  }
}

@media (width <= 880px) {
  .tabs {
    width: 320px;
  }

  .detailsText {
    padding: 30px 40px 40px 40px;
  }
}

@media (width <= 760px) {
  .tabs {
    display: none;
  }

  .content,
  .mobileTabsWrapper {
    display: block;
  }

  .content {
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    background-color: transparent;
    overflow: visible;
  }

  .detailsText {
    background-color: #fff;
    border-radius: 0 0 var(--border-radius) var(--border-radius);

    &:not(.active) {
      display: none;
    }
  }
}
