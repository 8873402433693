/* --------------------------- */
/* Partner Logo Slider */
/* --------------------------- */

.wrapper {
  --gradient-color: var(--fade-color, var(--section-background-color, #fff));

  margin-top: 60px;
}

.carouselWrapper {
  max-width: 1730px;
  margin: 40px auto 0 auto;

  :global(.line) {
    &:first-child {
      margin-top: -3px;
      top: 3px;
    }

    &:last-child {
      margin-bottom: -3px;
      bottom: 3px;
    }
  }
}

.carousel {
  --item-spacing: 55px;

  z-index: 20;
  padding: 45px 0;

  img {
    height: 40px;
    width: auto;
    display: block;
  }

  :global(.carousel-item) {
    filter: saturate(0) opacity(0.6);
    transition: filter 0.3s ease;

    &:hover {
      filter: none;
    }
  }
}

@media (width <= 720px) {
  .wrapper {
    margin-top: 50px;
  }

  .carousel {
    padding: 35px 0;
  }
}
