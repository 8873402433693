/* --------------------------- */
/* Browser Buttons */
/* --------------------------- */

.wrapper {
  display: flex;
  width: fit-content;
  margin-top: calc(var(--browser-button-offset) - 1px);
  margin-left: var(--browser-button-offset);
}

.circle {
  width: var(--browser-button-size, 9px);
  height: var(--browser-button-size, 9px);
  border-radius: 50%;
  margin-right: var(--browser-button-spacing, 7px);
  background-color: var(--browser-button-color, #d7d7e2);
  flex: none;

  &:nth-child(1) {
    --browser-button-color: #ff6058;
  }

  &:nth-child(2) {
    --browser-button-color: #febc2e;
  }

  &:nth-child(3) {
    --browser-button-color: #29c740;
  }

  &:last-child {
    margin-right: 0;
  }
}
