/* --------------------------- */
/* Loading Spinner */
/* --------------------------- */

$size: 16px;
$thickness: 2px;

.wrapper {
  width: 1.0625em;
  height: 1.0625em;
  flex: none;
  color: currentColor;
  animation: wrapperRotate 1.4s linear infinite;
  display: inline-block;
  position: relative;
}

.spinner {
  --size: var(--spinner-size, #{$size});
  --stroke-width: var(--spinner-stroke-width, #{$thickness});
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --path-length: calc(2 * 3.14159265359 * var(--radius));
  --half-path-length: calc(var(--path-length) / 2);

  display: block;
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  stroke: currentColor;
  stroke-width: var(--stroke-width);
  animation: circleDash 1.4s ease-in-out infinite;
  stroke-dasharray: 0px, var(--path-length);
  stroke-dashoffset: 0px;
  stroke-linecap: butt;
}

@keyframes wrapperRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes circleDash {
  0% {
    stroke-dasharray: 1px, var(--path-length);
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: var(--half-path-length), var(--path-length);
    stroke-dashoffset: calc(var(--path-length) * -0.3);
  }

  100% {
    stroke-dasharray: var(--half-path-length), var(--path-length);
    stroke-dashoffset: calc(var(--path-length) * -1 + var(--stroke-width));
  }
}

:export {
  baseSize: $size;
  baseThickness: $thickness;
}
