/* --------------------------- */
/* List */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  --line-height: 1.3;

  list-style: none;
  color: var(--headline-text-color);
  font-weight: 600;
  // font-size: inherit;
  // font-size: 17px;

  li {
    display: flex;
    position: relative;
    padding: var(--list-padding-y, 18px) var(--list-padding-x, 0px);
    margin: 0;
    line-height: var(--line-height);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: #{app.em(-5px, 17px)};

      &:after {
        display: none;
      }
    }
  }
}

.icon {
  --border-offset: 1px;
  --icon-size: #{app.em(20px, 17px)};
  --leading-height: calc(1em * var(--line-height));

  color: var(--icon-color, var(--light-gray-icon-color));
  margin-right: #{app.em(13px, 17px)};
  margin-top: calc(
    var(--leading-height) / 2 - var(--icon-size) / 2 - var(--border-offset)
  );

  @include app.retina {
    --border-offset: 0.75px;
  }
}
