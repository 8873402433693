/* --------------------------- */
/* Renters Page Hero */
/* --------------------------- */

.wrapper {
  --section-bottom-padding-offset: -25px;

  padding-top: 70px;
}

.contentWrapper {
  display: flex;
  align-items: center;
}

.text {
  max-width: 490px;
  width: 100%;
  flex: none;
  margin-bottom: var(--content-padding);

  h1 {
    max-width: 400px;
  }

  :global(.text-intro) {
    max-width: 450px;
  }

  :global(.platform-label) {
    color: var(--cerise-color);
    margin-bottom: 20px;
  }
}

.figure {
  --radius: 7px;
  --gap: 20px;

  flex: 1;
  margin: -60px 0 0 60px;
  display: grid;
  justify-content: end;
  justify-items: end;
  gap: var(--gap);
  grid-template-columns: auto auto;
}

.image {
  overflow: hidden;
  height: var(--image-height);

  &:nth-child(1) {
    --image-width: 238px;
    --image-height: 165px;

    border-radius: var(--radius) var(--radius) 0 0;
  }

  &:nth-child(2) {
    --image-width: 467px;
    --image-height: 473px;

    border-radius: 0 var(--radius) var(--radius) var(--radius);
    grid-row: span 3;
    margin-top: 45px;
  }

  &:nth-child(3) {
    --image-width: 315px;
    --image-height: 290px;

    border-radius: var(--radius) 0 0 var(--radius);
  }

  img {
    width: var(--image-width);
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.startYourApplicationLink {
  margin-top: 26px;

  a span {
    font-size: 1rem;
  }
}

@media (width <= 1560px) {
  .wrapper {
    --section-bottom-padding-offset: -10px;
  }

  .figure {
    margin-top: -30px;
  }
}

@media (width <= 1300px) {
  .figure {
    margin-top: -20px;
  }

  .image {
    &:nth-child(2) {
      --image-height: 405px;

      margin-top: 30px;
    }

    &:nth-child(3) {
      --image-height: 220px;
    }
  }
}

@media (width <= 1100px) {
  .wrapper {
    padding-top: 50px;
  }

  .contentWrapper {
    align-items: flex-start;
  }

  .text {
    max-width: 400px;
    margin-bottom: 0;
  }

  .figure {
    --radius: 5px;
    --gap: 15px;

    margin-top: 0;
    margin-left: 45px;
  }

  .image {
    &:nth-child(1) {
      border-radius: var(--radius) 0 0 0;
    }

    &:nth-child(2) {
      --image-height: calc(100% - var(--gap));

      margin-top: 0;
      border-radius: 0 var(--radius) var(--radius) 0;
    }

    &:nth-child(3) {
      --image-height: 180px;

      border-radius: 0 0 0 var(--radius);
    }
  }
}

@media (width <= 960px) {
  .text {
    max-width: 300px;
  }

  .image {
    &:nth-child(1),
    &:nth-child(3) {
      --image-width: 220px;
    }
  }
}

@media (width <= 780px) {
  .wrapper {
    --section-bottom-padding-offset: 0px;
  }

  .contentWrapper {
    display: block;
  }

  .text {
    max-width: none;

    h1 {
      max-width: none;
    }

    :global(.text-intro) {
      max-width: none;
    }
  }

  .figure {
    margin: var(--content-padding) 0 0 0;
    display: flex;
    justify-content: unset;
    justify-items: unset;
    gap: 0;
  }

  .image {
    width: 100%;
    height: 150px;
    margin-right: 10px;
    border-radius: 6px !important;

    &:last-child {
      margin-right: 0;
    }
  }
}
