/* --------------------------- */
/* Integrations */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  display: flex;
}

.content,
.videoWrapper {
  width: 50%;
}

.content {
  display: flex;
  align-items: center;
}

.text {
  padding: var(--section-padding) var(--content-padding);
  max-width: 610px;
  margin: 0 auto;
}

.button {
  margin-top: 35px;
}

.videoWrapper {
  background-color: #241d37;
  min-height: 530px;
  position: relative;
  color: #fff;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 40px 80px 40px;

  button {
    margin: auto 0;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 5;
    pointer-events: none;
    transition: opacity 0.2s;
    background: linear-gradient(
      180deg,
      rgba(#000, 0.22) 26.79%,
      rgba(#000, 0.23) 31.67%,
      rgba(#000, 0.24) 36.55%,
      rgba(#000, 0.26) 41.43%,
      rgba(#000, 0.29) 46.31%,
      rgba(#000, 0.32) 51.19%,
      rgba(#000, 0.37) 56.07%,
      rgba(#000, 0.42) 60.95%,
      rgba(#000, 0.47) 65.83%,
      rgba(#000, 0.52) 70.72%,
      rgba(#000, 0.56) 75.6%,
      rgba(#000, 0.6) 80.48%,
      rgba(#000, 0.63) 85.36%,
      rgba(#000, 0.65) 90.24%,
      rgba(#000, 0.66) 95.12%,
      rgba(#000, 0.67) 100%
    );
  }

  &.played:before {
    opacity: 0;
  }
}

.play {
  --outline-color: var(--yellow-color);
  --fill: #fff;

  @include app.disable-native-button($transparentBg: true);

  border-radius: 50%;
  width: 85px;
  height: 85px;
  padding: 5px;
  position: relative;
  z-index: 10;
  transition: visibility step-end 0.2s, opacity 0.2s;

  svg {
    width: 100%;
    height: auto;
    display: block;

    path {
      fill: var(--fill);
    }
  }

  &:hover {
    --fill: var(--yellow-color);
  }

  @at-root .played & {
    opacity: 0;
    visibility: hidden;
  }
}

// .video {

// }

.video,
.videoThumb {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.video {
  pointer-events: none;

  @at-root .played & {
    pointer-events: auto;
  }
}

.caption {
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  font-family: var(--font-headline);
  position: relative;
  z-index: 10;
  line-height: 1.4;
  transition: visibility step-end 0.2s, opacity 0.2s;
  text-wrap: balance;

  @at-root .played & {
    opacity: 0;
    visibility: hidden;
  }

  strong {
    font-weight: inherit;
  }
}

.captionLabel {
  font-weight: 600;
  font-family: var(--font-body);
  font-size: 16px;
  color: var(--yellow-color);
  letter-spacing: 0.07em;
  text-transform: uppercase;
  display: block;
  margin-bottom: 15px;
}

@media (width <= 1560px) {
  .videoWrapper {
    min-height: 510px;
  }
}

@media (width <= 1420px) {
  .content {
    width: auto;
    flex-grow: 1;
  }

  .videoWrapper {
    width: 800px;
    flex: none;
  }
}

@media (width <= 1200px) {
  .videoWrapper {
    width: 700px;
    min-height: 450px;
  }
}

@media (width <= 1075px) {
  .videoWrapper {
    width: 580px;
  }
}

@media (width <= 920px) {
  .wrapper {
    display: block;
  }

  .content,
  .videoWrapper {
    width: 100%;
  }

  .text {
    max-width: none;
    margin: 0;
  }
}

@media (width <= 670px) {
  .videoWrapper {
    min-height: 400px;
    padding: 40px;
  }

  .caption {
    font-size: 25px;
  }
}

@media (width <= 500px) {
  .videoWrapper {
    min-height: 350px;
  }

  .play {
    width: 75px;
    height: 75px;
  }
}
