/* --------------------------- */
/* Testimonials Slider */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  --headline-color: #fff;
  --border-radius: 17px;
  --arrow-size: 78px;
  --photo-size: 165px;

  background-color: var(--dark-purple-bg-color);
  max-width: 1290px;
  margin: 0 auto;
  border-radius: var(--border-radius);
  color: #fff;
  position: relative;

  @at-root :global(.section-top) + & {
    margin-top: var(--section-padding);
  }

  &:before {
    content: '';
    width: 80px;
    height: 80px;
    background-image: svg-load('./quote-circle.svg');
    background-size: cover;
    position: absolute;
    inset: 0 0 auto 50%;
    transform: translate(-50%, -30%);
    z-index: 10;
  }
}

.nav {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 20;

  &:before {
    content: '';
    position: absolute;
    inset: 0 -60px 0 0;
    pointer-events: none;
    background: linear-gradient(
      90deg,
      #241d37 0%,
      rgba(36, 29, 55, 0.99) 6.67%,
      rgba(36, 29, 55, 0.96) 13.33%,
      rgba(36, 29, 55, 0.92) 20%,
      rgba(36, 29, 55, 0.85) 26.67%,
      rgba(36, 29, 55, 0.77) 33.33%,
      rgba(36, 29, 55, 0.67) 40%,
      rgba(36, 29, 55, 0.56) 46.67%,
      rgba(36, 29, 55, 0.44) 53.33%,
      rgba(36, 29, 55, 0.33) 60%,
      rgba(36, 29, 55, 0.23) 66.67%,
      rgba(36, 29, 55, 0.15) 73.33%,
      rgba(36, 29, 55, 0.08) 80%,
      rgba(36, 29, 55, 0.04) 86.67%,
      rgba(36, 29, 55, 0.01) 93.33%,
      rgba(36, 29, 55, 0) 100%
    );
  }
}

.prev {
  left: 0;
}

.next {
  right: 0;
  transform: rotate(180deg);
}

.navButton {
  @include app.disable-native-button($transparentBg: true);

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  padding: 20px;
  border-radius: var(--border-radius) 0 0 var(--border-radius);

  svg {
    width: var(--arrow-size);
    height: var(--arrow-size);
  }

  path {
    fill: var(--arrow-color, #9588b5);
    transition: fill 0.2s;
  }

  &.disabled {
    --arrow-color: #5b5173;

    pointer-events: none;
    outline: none;
  }

  &:not(.disabled):hover {
    --arrow-color: #ddcffe;
  }

  &:focus-visible {
    outline-offset: -8px;
  }
}

.sliderWrapper {
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius);
  position: relative;
}

.slider {
  display: flex;
  width: 100%;
  transform: translateX(calc(-100% * var(--active-index, 0)));
  transition: transform 0.5s ease;
  padding-top: 50px;
}

.slide {
  width: 100%;
  flex: none;
  display: flex;
  justify-content: center;
}

.slideContent {
  padding: 100px;
  display: flex;
}

.slideText {
  flex: 1;
}

.testimonialText {
  position: relative;
  font-size: 18px;
  max-width: 570px;

  &:before {
    content: '';
    width: 30px;
    height: 22px;
    position: absolute;
    top: 8px;
    left: -55px;
    background-image: svg-load('./quote-icon.svg');
    background-size: cover;
  }
}

.attribution {
  margin-top: 32px;
  color: #d9ccff;
  font-size: 16px;
  font-weight: 500;
}

.name {
  font-size: 27px;
  color: #fff;
  font-weight: 600;
  display: block;
  line-height: 1.5;
}

.photo {
  width: var(--photo-size);
  height: var(--photo-size);
  flex: none;
  border-radius: 50%;
  overflow: hidden;

  &:not(:first-child) {
    margin-left: 60px;
  }
}

@media (width <= 1000px) {
  .wrapper {
    --arrow-size: 60px;
  }

  .slider {
    padding-top: 10px;
  }

  .nav {
    &:before {
      right: -10px;
    }
  }

  .navButton {
    padding: 10px;
  }

  .slideContent {
    padding: 70px 80px;
  }

  .testimonialText {
    font-size: 17px;

    &:before {
      display: block;
      position: static;
      margin-bottom: 30px;
    }
  }

  .name {
    font-size: 24px;
  }
}

@media (width <= 680px) {
  .wrapper {
    --arrow-size: 50px;
  }

  .slideContent {
    padding: 60px 70px;
  }

  .testimonialText {
    font-size: 16px;
  }
}

@media (width <= 510px) {
  .wrapper {
    --offset: 10px;

    width: calc(100% + var(--offset) * 2);
    margin-left: calc(var(--offset) * -1);
    margin-bottom: calc(var(--offset) * -2);

    &:before {
      width: 70px;
      height: 70px;
    }
  }

  .testimonialText {
    &:before {
      width: 26px;
      height: 19px;
      background-size: contain;
      margin-bottom: 25px;
    }
  }
}

@media (width <= 400px) {
  .slideContent {
    padding: 50px 60px;
  }

  .navButton {
    padding: 5px;
  }

  .testimonialText {
    font-size: 15px;
  }

  .name {
    font-size: 20px;
  }

  .attribution {
    font-size: 14px;
  }
}
