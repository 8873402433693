/* --------------------------- */
/* Platform Overview */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  --box-padding: 70px;
  --box-border-radius: 20px;
  --box-margin: 40px;
  --content-max-width: calc(var(--content-width) + 2 * var(--box-padding));
  --content-padding: var(--section-padding);

  background-color: var(--medium-gray-bg-color);
}

.box {
  background: #fff;
  border-radius: var(--box-border-radius);
  margin-bottom: var(--box-margin);
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  @extend %last-child-no-margin;

  :global {
    .platform-label {
      margin-bottom: 18px;
      color: var(--accent-color, var(--purple-color));
    }

    // .list {
    //   max-width: 450px;
    //   margin-top: 33px;
    // }

    .arrow-button {
      --button-text-color: var(--accent-color);

      margin-top: 50px;
    }
  }
}

.list {
  max-width: 450px;
  margin-top: 33px;
  font-size: 17px;
}

.text {
  padding: var(--box-padding);
  max-width: calc(var(--text-max-width) + var(--box-padding) * 2);
  flex: 1;

  h2 {
    margin-bottom: 0.65em;
  }
}

.figure {
  flex: 1;
  position: relative;
  max-width: var(--figure-max-width, none);
}

.landlords {
  --figure-max-width: 785px;
  --figure-offset: 50px;
  --figure-border-radius: 15px;
  --accent-color: var(--dark-purple-color);
  --text-max-width: 480px;

  .figure {
    border-radius: var(--figure-border-radius) var(--figure-border-radius) 0 0;
    margin: var(--figure-offset) var(--figure-offset) 0 0;
    overflow: hidden;
    box-shadow: 0px 1.771px 7.167px 0px rgba(34, 21, 55, 0.01),
      0px 4.256px 17.224px 0px rgba(34, 21, 55, 0.02),
      0px 8.014px 32.431px 0px rgba(34, 21, 55, 0.03),
      0px 14.295px 57.851px 0px rgba(34, 21, 55, 0.03),
      0px 26.738px 108.204px 0px rgba(34, 21, 55, 0.04),
      0px 64px 259px 0px rgba(34, 21, 55, 0.05);
  }
}

.renters {
  --figure-offset: 14px;
  --figure-border-radius: calc(var(--box-border-radius) - var(--figure-offset));
  --accent-color: var(--cerise-color);
  --text-max-width: 450px;

  .figure {
    padding: var(--figure-offset) var(--figure-offset) var(--figure-offset) 0;
    overflow: hidden;
    border-left: 1px solid #fff;

    &:before {
      content: '';
      position: absolute;
      inset: -1px auto -1px -1px;
      width: 85px;
      z-index: 2;
      background-image: svg-load('./img-mask.svg');
      background-size: cover;
      background-position: center right;
    }

    img {
      border-radius: 0 var(--figure-border-radius) var(--figure-border-radius) 0;
    }
  }
}

.imgMask {
  position: absolute;
  inset: 0 auto 0 0;
  width: 84px;
  height: 100%;
}

// @media (width <= 1560px) {
//   .figure {
//     width: 650px;
//   }
// }

// @media (width <= 1350px) {
//   .wrapper {
//     --box-padding: 60px;
//   }

//   .figure {
//     width: 550px;
//   }
// }

@media (width <= 1470px) {
  .renters {
    --text-max-width: 380px;

    .text {
      padding-right: calc(var(--box-padding) - 30px);
    }
  }
}

@media (width <= 1200px) {
  .wrapper {
    --section-padding: 40px;
    --box-padding: 50px;
  }

  .landlords {
    --text-max-width: 400px;
  }
}

@media (width <= 1070px) {
  .wrapper {
    font-size: 16px;

    h2 {
      font-size: 28px;
    }
  }

  .box {
    :global(.arrow-button) {
      margin-top: 40px;
    }
  }

  .list {
    --list-padding-y: 13px;

    font-size: 15px;
    margin-top: 25px;
  }

  .landlords {
    --text-max-width: 350px;
  }

  .renters {
    --text-max-width: 300px;
  }
}

@media (width <= 970px) {
  .wrapper {
    --section-padding: 35px;
    --box-padding: 40px;
    --box-border-radius: 15px;
    --box-margin: var(--section-padding);
  }
}

@media (width <= 910px) {
  .landlords {
    --text-max-width: none;

    display: block;

    .figure {
      margin: 0 30px;
      height: 500px;
    }
  }

  .renters {
    --figure-border-radius: 5px;

    flex-direction: column;

    .figure {
      order: 1;
      padding: 15px 15px 0 15px;
      overflow: hidden;
      margin: 0;
      height: 430px;
      flex: none;

      &:before {
        display: none;
      }

      img {
        border-radius: var(--figure-border-radius) var(--figure-border-radius) 0
          0;
      }
    }

    .text {
      order: 2;
    }
  }
}

@media (width <= 650px) {
  .renters {
    .figure {
      height: 320px;
    }
  }
}

@media (width <= 580px) {
  .wrapper {
    --section-padding: 20px;
    --box-padding: 35px;
  }

  .landlords {
    .figure {
      margin: 0;
      border-radius: 0;
      height: 400px;
    }
  }
}

@media (width <= 460px) {
  .wrapper {
    --section-padding: 16px;
    --box-padding: 30px;
  }
}
