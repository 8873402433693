/* --------------------------- */
/* Mixins */
/* --------------------------- */

@use 'sass:selector';
@use 'sass:meta';
@use 'functions';

@mixin target-ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin disable-native-button($transparentBg: false, $noOutline: false, $noBorder: true, $fontFamily: var(--font-body)) {
  appearance: none;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  font-family: $fontFamily;
  user-select: none;

  @if $noBorder {
    border: none;
  }

  @if $noOutline {
    outline: none;
  }

  @if $transparentBg {
    background-color: transparent;
  }
}

@mixin highlight {
  @at-root #{&} ::selection {
    @content;
  }

  @at-root #{&} ::-moz-selection {
    @content;
  }
}

@mixin css-properties($vars, $suffix: '') {
  @each $name, $value in $vars {
    --#{functions.kebab-case($name)}#{$suffix}: #{$value};
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin autocomplete($color: null, $backgroundColor: null) {
  &:-webkit-autofill {
    @if $color != null {
      -webkit-text-fill-color: $color;
    }

    @if $backgroundColor != null {
      -webkit-box-shadow: 0 0 0 1000px $backgroundColor inset;
    }

    @content;
  }
}

@mixin retina {
  @media (min-resolution: 2dppx) {
    @content;
  }
}

@mixin supports-css-paint {
  @supports (background: paint(something)) {
    @content;
  }
}

@mixin icon($names...) {
  @each $name in $names {
    @if $name == '*' {
      @content;
      use {
        @content;
      }
    } @else {
      [href='#icon-#{$name}'] {
        @content;
      }
    }
  }
}

// Mixin for setting different font sizes for
// large/small screens. We use a CSS variable to
// allow relative font-size values with calc()
@mixin font-size($desktopSize, $mobileSize: null) {
  --font-size: #{$desktopSize};

  @if $mobileSize {
    @media (width <= 960px) {
      --font-size: #{$mobileSize};
    }
  }
}
