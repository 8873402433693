/* --------------------------- */
/* Renters Page Start */
/* --------------------------- */

@use 'www/styles/abstracts/app';
@import 'app/assets/stylesheets/color_variables.scss';

.wrapper {
  padding: 72px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    padding: 36px 24px;
  }
}

.contentWrapper {
  display: flex;
  overflow: hidden;
  background: white;
  width: 100%;
  max-width: 86rem;
  border-radius: 16px;
  padding: 0;
  box-shadow: 0px 8px 20px 0px rgba(112, 144, 176, 0.25);

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 72px;

    @media (max-width: 1280px) {
      padding: 24px;
    }

    &:nth-child(2) {
      padding: 72px 64px;
      background: #F8FAFF;

      @media (max-width: 1280px) {
        padding: 24px;
      }
    }
  }

  @media (width <= 1280px) {
    flex-flow: column-reverse;
  }
}

.formContainer {
  form {
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media screen and (max-width: 1280px) {
      gap: 24px;
    }

    .headingContainer {
      padding: 0;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
      }

      h2 {
        font-size: 1.5rem;
        font-weight: normal;
        font-family: var(--font-body);
        color: #47414F;
      }

      @media screen and (max-width: 900px) {
        h1 {
          font-size: 2rem;
        }

        h2 {
          font-size: 1.25rem;
        }

      }
    }

    label {
      display: flex;
      flex-direction: column;
      gap: 2px;
      font-size: 20px;
      font-weight: 700;
      color: #212526;
    }

    input {
      padding: 16px;
      border: 1px solid #E0E0E0;
      font-size: 1rem;
      font-family: var(--font-body);
      color: #47414F;
    }

    .enrollAddressInput {
      width: 100%;
      position: relative;
    }

    button:not(.closeDialog) {
      align-self: center;
      margin-top: 56px;
      width: 100%;
      max-width: 296px;
      padding: 22px 16px;
      background: #6B3AF7;
      color: white;
      font-size: 1rem;
      font-weight: bold;
      border: none;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background: #502CB9;
      }

      &:disabled {
        background: #ADB4B8;
        color: white;
        cursor: not-allowed;
      }
    }
  }
}

.enrollSteps {
  display: flex;
  flex-direction: column;
  gap: 64px;

  @media screen and (max-width: 900px) {
    gap: 32px;
  }
}

.hero {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  height: 380px;
  width: 100%;
  max-width: 560px;
  position: relative;

  .figureEnroll {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    place-self: center;
    z-index: 1;

    img {
      border-radius: 16px;
      height: 380px;
      width: 100%;
      max-width: 315px;
      box-shadow: 0px 16px 40px 0px rgba(112, 144, 176, 0.25);
    }
  }

  .figureSecurityDeposit {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-self: center;
    z-index: 2;

    img {
      border-radius: 4px;
      width: 184px;
      height: auto;
      box-shadow: 0px 9px 22px 0px rgba(112, 144, 176, 0.25);
    }
  }

  .figureReview {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    place-self: end;
    align-self: start;
    z-index: 2;

    img {
      border-radius: 4px;
      width: 214px;
      height: auto;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.06);    }
  }
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: center;

  h3 {
    margin: 0;
    font-size: 1.5rem;

    span {
      font-size: 1rem;
      margin-bottom: 8px;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    list-style: none;

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;

      span {
        height: 34px;
        color: #5A6468;
        font-size: 21px;
        font-weight: 700;
      }

      p {
        font-size: 14px;
        font-family: var(--font-body);
        color: #5A6468;
        white-space: nowrap;
      }

      &:nth-child(1), &:nth-child(2) {
        span {
          background: #6B3AF7;
          color: white;
          border-radius: 50%;
          width: 34px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        p {
          color: #212526;
        }
      }
    }
  }
}

dialog {
  inset: 0;
  margin: auto;
  padding: 24px;
  background: white;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  box-shadow: 0px 8px 20px 0px rgba(112, 144, 176, 0.25);

  &::backdrop {
    background: rgba(0, 0, 0, .5);
  }

  nav {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 16px;

    header {
      color: #212526;
      font-family: var(--font-headline);
      font-size: 1.5rem;
      font-weight: bold;
      text-wrap: pretty;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  section {
    display: flex;
    flex-direction: column;

    p {
      font-size: 1rem;
      font-family: var(--font-body);
      color: #212526;
    }
  }
}

:global {
  body:has(dialog[open]) {
    overflow: hidden;
  }

  .autocomplete-input-wrapper {
    position: relative;
    border-radius: 4px;

    input {
      width: 100%;
    }

    div.autocomplete-item {
      padding: 8px 16px !important;
      cursor: default;
      z-index: 1000;
      box-sizing: border-box;
      cursor: pointer;
      font-family: var(--font-body);
      font-weight: 400;
      background: white;

      &:first-child {
        border-radius: 3px 3px 0 0;
      }

      &:last-child {
        border-radius: 0 0 3px 3px;
      }

      &.highlighted {
        color: #212526;
        background: $palette_brand4;
        font-style: normal;
        padding: 0;
      }
    }
  }
}
