/* --------------------------- */
/* Star Rating */
/* --------------------------- */

.wrapper {
  --gap: var(--star-gap, 4px);
  --base-star-size: 12px;
  --star-rating: var(--rating, 5);

  position: relative;
  overflow: hidden;
}

.stars {
  display: flex;
  margin-left: calc(var(--gap) / -2);

  span {
    padding: 0 calc(var(--gap) / 2);
  }

  svg {
    width: var(--star-size, var(--base-star-size));
    height: var(--star-size, var(--base-star-size));
    display: block;
    flex: none;

    &:last-child {
      margin-right: 0;
    }
  }
}

.active {
  --percent: calc(var(--rating) * 20%);

  color: var(--active-star-color, #515151);
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(
    0 0,
    calc((var(--rating)) * 20%) 0,
    calc((var(--rating)) * 20%) 100%,
    0 100%
  );
}

.inactive {
  color: var(--inactive-star-color, #e3dcf3);
}
