/* --------------------------- */
/* Renters How it Works */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  --section-top-padding: 20px;
  --content-max-width: 1100px;
}

.listWrapper {
  display: flex;
  justify-content: space-between;
}

.line {
  margin: 42px 0 50px 0;
}

.figure {
  width: 440px;
  min-height: 680px;
  margin: -140px 0 calc(var(--section-padding) * -1) 0;
  contain: strict;
  overflow: hidden;
}

.list {
  flex: 1;
  margin-right: 80px;
  max-width: 460px;
  counter-reset: list-counter;
  list-style: none;
  contain: style;
}

.item {
  --number-font-size: #{app.rem(18px)};
  --number-size: #{app.rem(32px)};
  --number-margin: 18px;

  margin-bottom: 35px;
  font-size: 21px;
  // display: flex;
  counter-increment: list-counter;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  --px: 20px;
  --py: 16px;
  --my: calc(var(--py) * -1);
  --mx: calc(var(--px) * -1);
  --outline-offset: 0;
  --hover-bg: #f9f9ff;

  @include app.disable-native-button($transparentBg: true);

  display: flex;
  font-weight: 500;
  font-size: inherit;
  padding: var(--py) calc(var(--px) + 3px) var(--py) var(--px);
  margin: var(--my) 0 var(--my) var(--mx);
  border-radius: 8px;
  text-align: left;
  transition: background-color 0.2s;
  text-wrap: pretty;
  will-change: background-color;

  @at-root .active & {
    cursor: default;
  }

  @at-root .item:not(.active) & {
    &:hover {
      background-color: var(--hover-bg);
    }
  }
}

.number {
  display: inline-flex;
  flex: none;
  font-size: var(--number-font-size);
  width: var(--number-size);
  height: var(--number-size);
  margin: -1px var(--number-margin) 0 0;
  font-weight: 700;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #82879c;
  border: 2px solid #a3a7b7;
  transition: 0.2s ease;
  transition-property: background-color, color, border-color;
  position: relative;

  @at-root .active & {
    color: #fff;
    border-color: var(--headline-text-color);
    background-color: var(--headline-text-color);
  }

  @at-root .item:not(.active) .title:hover & {
    color: #72768a;
    border-color: #85899b;
  }

  &:before {
    content: counter(list-counter);
  }
}

.progress {
  --pi: 3.14159265359;
  --stroke-width: 2.5px;
  --offset: 4px;
  --size: calc(
    var(--number-size) + var(--stroke-width) * 2 + var(--offset) * 2
  );
  --radius: calc(var(--size) / 2 - var(--stroke-width) / 2 - var(--offset) / 2);
  --circumference: calc(var(--pi) * (var(--radius) * 2));

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  width: var(--size);
  height: var(--size);
  pointer-events: none;
  z-index: 100;

  @at-root .item:not(.active) &,
    .disableAutoplay & {
    display: none;
  }

  circle {
    r: var(--radius);
    stroke-width: var(--stroke-width);
    stroke: var(--headline-text-color);
    stroke-dashoffset: calc(var(--circumference) * (1 - var(--progress, 0)));
    stroke-dasharray: var(--circumference);
  }
}

.description {
  height: 0;
  overflow: hidden;
  will-change: height;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  padding-left: calc(var(--number-size) + var(--number-margin));

  @at-root .active & {
    height: auto;
  }
}

.itemText {
  padding-left: 30px;
  margin-top: 35px;
  margin-bottom: 10px;
  font-weight: 600;
  position: relative;
  font-size: 20px;
  line-height: 1.5;
  max-width: 350px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: calc(100% - 14px);
    transform: translateY(-50%);
    width: 3px;
    min-height: 1em;
    background-color: #e6e5f5;
  }
}

@media (width <= 990px) {
  .figure {
    width: 390px;
    min-height: 550px;
  }

  .list {
    margin-right: 60px;
  }

  .description {
    padding-left: calc(var(--number-size) / 2);
  }

  .item {
    --number-font-size: #{app.rem(16px)};
    --number-size: #{app.rem(28px)};
    --number-margin: 16px;

    margin-bottom: 25px;
    font-size: 19px;
  }

  .title {
    --px: 15px;
    --py: 12px;
  }

  .progress {
    --offset: 5px;
  }

  .itemText {
    font-size: 17px;
    margin-top: 25px;
    margin-bottom: 5px;
    padding-left: 20px;

    p {
      text-wrap: balance;
    }
  }
}

@media (width <= 860px) {
  .figure {
    width: 306px;
  }
}

@media (width <= 860px) {
  .wrapper {
    h2 {
      margin-bottom: 40px;
    }
  }

  .line {
    display: none;
  }

  .figure {
    margin-top: 0;
    min-height: 480px;
  }

  .list {
    margin-right: 30px;
  }
}

@media (width <= 625px) {
  .wrapper {
    --section-bottom-padding: 0;

    h2 {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .contentWrapper {
    padding: 0;
  }

  .listWrapper {
    position: relative;
    padding-top: 350px;
  }

  .figure {
    position: absolute;
    min-height: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0 20px;

    :global(.animation-wrapper) {
      max-width: 306px;
      margin: 0 auto;

      @media (height <= 670px) {
        max-width: 257px;
      }
    }
  }

  .list {
    z-index: 10;
    background-color: rgba(#fff, 0.88);
    backdrop-filter: blur(10px);
    padding: var(--content-padding);
    max-width: none;
    margin: 0;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      inset: 40px 0 0 0;
      background: linear-gradient(
        180deg,
        rgba(#fff, 0) 0%,
        rgba(#fff, 0.01) 6.67%,
        rgba(#fff, 0.04) 13.33%,
        rgba(#fff, 0.08) 20%,
        rgba(#fff, 0.15) 26.67%,
        rgba(#fff, 0.23) 33.33%,
        rgba(#fff, 0.33) 40%,
        rgba(#fff, 0.44) 46.67%,
        rgba(#fff, 0.56) 53.33%,
        rgba(#fff, 0.67) 60%,
        rgba(#fff, 0.77) 66.67%,
        rgba(#fff, 0.85) 73.33%,
        rgba(#fff, 0.92) 80%,
        rgba(#fff, 0.96) 86.67%,
        rgba(#fff, 0.99) 93.33%,
        #fff 100%
      );
    }
  }

  .item {
    --number-font-size: #{app.rem(14px)};
    --number-size: #{app.rem(24px)};
    --number-margin: 12px;

    font-size: 16px;
    margin-bottom: 20px;
    position: relative;
    z-index: 15;
  }

  .title {
    --px: 10px;
    --py: 10px;
    --hover-bg: rgba(0, 0, 0, 0.08);

    text-wrap: pretty;
    width: calc(100% + var(--px) * 2);
  }

  .progress {
    --offset: 6px;
  }

  .itemText {
    max-width: none;
    color: var(--headline-text-color);
    display: block;
    font-size: 15px;
    margin-top: 15px;
    padding-left: 15px;

    &:before {
      background-color: #54535a;
      width: 2px;
    }
  }
}

@media (width <= 400px) {
  .list {
    padding: 25px;
  }

  .itemText {
    font-size: 14px;
    font-weight: 500;
  }
}
