/* --------------------------- */
/* Stats Section */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.wrapper {
  --section-padding: 100px;
  --content-max-width: 1500px;

  background-color: var(--bg-color, var(--light-gray-bg-color));
  overflow: hidden;
}

.contentWrapper {
  max-width: none;
}

.content {
  --gap-x: var(--item-gap-x, 130px);
  --gap-y: var(--item-gap-y, 50px);
  --divider-height: 100px;

  width: calc(100% + var(--gap-x));
  margin-left: calc(var(--gap-x) / -2);
  margin-bottom: calc(var(--gap-y) * -1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &:empty {
    margin-bottom: 0;
  }

  @at-root :global(.section-top) + & {
    padding-top: 20px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 1.3;
  padding: 0 calc(var(--gap-x) / 2);
  margin-bottom: var(--gap-y);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: var(--divider-height);
    top: 50%;
    right: -1px;
    transform: translateY(-50%);
    background-color: var(--divider-color, #bbbcc1);

    // background-color: red;
    mask-image: svg-load('../../images/dashed-line-vertical.svg');

    @include app.retina {
      mask-image: svg-load('../../images/dashed-line-vertical-retina.svg');
    }
  }

  &:last-child:after {
    display: none;
  }
}

.itemText {
  color: var(--purple-color);
  font-family: var(--font-headline);
  font-size: 65px;
  font-weight: 500;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  line-height: 1;

  :global(.small) {
    font-size: 16px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-weight: 600;
    font-family: var(--font-body);
    color: var(--headline-text-color);
    margin: 0 0.7em 0 0.53em;
    width: fit-content;
    flex: none;
    line-height: 1.3;

    &:has(br) {
      margin-right: 0.75em;
    }
  }
}

.topLabel {
  margin-bottom: 20px;
}

.bottomLabel {
  margin-top: 20px;
}

@media (width <= 1500px) {
  .content {
    --gap-x: var(--item-gap-x, 100px);
    --divider-height: 80px;
  }

  .itemText {
    font-size: 60px;

    :global(.small) {
      font-size: 15px;
    }
  }
}

@media (width <= 1280px) {
  .content {
    --gap-x: var(--item-gap-x, 80px);
    --divider-height: 60px;
  }

  .itemText {
    font-size: 50px;

    :global(.small) {
      font-size: 13px;
    }
  }
}

@media (width <= 1115px) {
  .wrapper {
    --section-padding: 50px;
    // --content-padding: 50px;
  }

  .content {
    --divider-height: 100px;

    width: 100%;
    margin: 0;
  }

  .item {
    width: 50%;

    @media (width > 810px) {
      &:nth-child(even) {
        &:after {
          display: none;
        }
      }
    }
  }
}

@media (width <= 810px) {
  .wrapper {
    --section-padding: 70px;
  }

  .content {
    --gap-y: 80px;

    display: block;
  }

  .item {
    width: 100%;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      top: auto;
      left: 50%;
      right: auto;
      bottom: calc(var(--gap-y) / -2 - 1px);
      transform: translateX(-50%);
      width: 100px;
      height: 2px;
      mask-image: svg-load('../../images/dashed-line.svg');

      @include app.retina {
        mask-image: svg-load('../../images/dashed-line-retina.svg');
      }
    }
  }
}

@media (width <= 450px) {
  .itemText {
    font-size: 40px;
  }
}
