/* --------------------------- */
/* Footer */
/* --------------------------- */

.wrapper {
  background-color: #191329;
  color: #fff;
  padding: 80px 0;
  font-size: 16px;

  svg {
    display: block;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.logo {
  width: 94px;
  display: block;
  flex: none;
  margin-right: 40px;

  svg {
    width: 100%;
    height: auto;
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  list-style: none;

  li {
    margin-right: var(--gap, 25px);

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    line-height: 1.1;

    &:hover {
      color: var(--yellow-color);
    }
  }
}

.mainLinks {
  margin-right: auto;
  margin-bottom: 25px;
  padding-right: 20px;
}

.social {
  flex-wrap: nowrap;
  margin-bottom: 25px;

  svg {
    display: block;
    width: 23px;
    height: 23px;
  }

  path {
    fill: currentColor;
  }
}

.bottomBar {
  padding-top: 45px;
  margin-top: 20px;
  font-size: 15px;
  color: #d0d3e5;
  border-top: 1px solid #261e3c;
}

@media (width <= 600px) {
  .wrapper {
    padding: 40px 0;
  }

  .row {
    display: block;
  }

  .logo {
    margin-bottom: 20px;
  }

  .mainLinks {
    padding-right: 0;
    margin-right: 0;
  }

  .bottomBar {
    padding-top: 20px;
  }
}

@media (width <= 500px) {
  .bottomBar {
    margin-top: 30px;
    padding-top: 25px;

    .links {
      display: block;

      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (width <= 440px) {
  .mainLinks {
    display: block;
    margin: 30px 0 35px 0;

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
