/* --------------------------- */
/* Value Proposition */
/* --------------------------- */

.wrapper {
  background-color: #fafafd;

  :global(.disclosure) {
    text-align: center;
    margin-top: var(--content-padding);
  }
}

.itemsWrapper {
  --item-margin: 150px;
  --preview-margin: 100px;

  padding-bottom: 40px;
}

.item {
  display: flex;
  justify-content: space-between;
  margin-top: var(--item-margin);

  h3 {
    text-wrap: pretty;
  }
}

.text {
  align-self: center;
  max-width: 480px;

  @at-root .item:nth-child(even) & {
    order: 2;
  }
}

.figure {
  width: 690px;
  height: 365px;
  flex: none;
  border-radius: 200px 8px 8px 200px;
  margin-left: var(--preview-margin);
  overflow: hidden;

  // Set the background color on the SVG rather than the wrapper
  // to prevent some of the color bleeding into the animation
  svg {
    background-color: var(--purple-color);
  }

  // Animations that contain text spans will be hidden until
  // webfonts are finished loading, so we show a purple
  // background on the wrapper until the poster is displayed
  @supports selector(.item:not(:has(svg))) {
    &:not(:has(svg)) {
      background-color: var(--purple-color);
    }
  }

  @at-root .item:nth-child(even) & {
    border-radius: 8px 200px 200px 8px;
    margin-left: 0;
    margin-right: var(--preview-margin);
    order: 1;
  }

  :global(.animation-wrapper) {
    @media (width > 890px) {
      width: calc(100% + 50px);

      @at-root .item:nth-child(even) & {
        margin-left: -50px;
      }
    }
  }
}

.blank {
  width: 100%;
  height: 100%;
  background-color: var(--purple-color);
}

@media (width <= 1300px) {
  .figure {
    width: 580px;
  }
}

@media (width <= 1200px) {
  .itemsWrapper {
    --item-margin: 80px;
    --preview-margin: 60px;
  }
}

@media (width <= 1050px) {
  .figure {
    width: 520px;
    height: 300px;
  }
}

@media (width <= 960px) {
  .text {
    font-size: 17px;
  }

  .figure {
    width: 490px;
  }
}

@media (width <= 900px) {
  .itemsWrapper {
    --item-margin: 70px;
    --preview-margin: 40px;
  }

  .figure {
    width: 460px;
  }
}

@media (width <= 890px) {
  .itemsWrapper {
    --item-margin: var(--content-padding);

    padding: 0;
  }

  .item {
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
    border: 3px solid var(--purple-color);
  }

  .figure {
    order: 0;
    border-radius: 0 !important;
    width: 100%;
    height: 42vw;
    margin: 0;
  }

  .text {
    order: 1;
    max-width: none;
    padding: 40px;
  }
}

@media (width <= 600px) {
  .figure {
    --animation-offset: 30px;

    :global(.animation-wrapper) {
      width: calc(100% + var(--animation-offset));
      margin-left: calc(var(--animation-offset) / -2);
    }
  }
}

@media (width <= 550px) {
  .text {
    padding: 30px;
  }
}
