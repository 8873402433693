/* --------------------------- */
/* Button */
/* --------------------------- */

@use 'www/styles/abstracts/app';
@use 'sass:math';

.button {
  --border-width: #{app.em(2)};
  --py: var(--button-padding-y, #{app.em(19px)});
  --px: var(--button-padding-x, #{app.em(32px)});
  --badge-border-color: #a788ff;

  @include app.retina {
    --border-width: #{app.em(2.5)};
  }

  $paddingY: calc(var(--py) - var(--border-width));
  $paddingX: calc(var(--px) - var(--border-width));

  @include app.disable-native-button($noBorder: false);

  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  background-color: var(--button-background-color, var(--purple-color));
  font-weight: 600;
  color: var(--button-text-color, #fff);
  line-height: 1;
  font-size: var(--button-font-size, var(--base-font-size, app.rem(16px)));
  transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-property: background-color, border-color, color;
  padding: $paddingY $paddingX;
  border: var(--border-width) solid var(--button-border-color, transparent);
  position: relative;
  user-select: none;
  text-decoration: none;
  border-radius: #{app.em(6px)};
  text-align: center;

  @at-root p & {
    vertical-align: baseline;
  }

  @media (width <= 960px) {
    font-size: var(--button-font-size, var(--base-font-size, app.rem(15px)));
  }

  &:hover {
    --badge-border-color: #8264d7;

    background-color: var(--button-background-color, var(--purple-hover-color));
  }

  &:focus {
    z-index: 100;
  }

  &.hasBadge {
    padding-left: calc(var(--py) + 1px);
  }

  &:global(.medium) {
    --button-padding-y: #{app.em(19px)};
    --button-padding-x: #{app.em(29px)};
    --base-font-size: #{app.rem(14px)};
  }

  &:global(.small) {
    --base-font-size: #{app.rem(13px)};
    --button-padding-y: #{app.em(16px)};
    --button-padding-x: #{app.em(22px)};
  }

  &.loading {
    pointer-events: none;
  }
}

.text {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @at-root .loading & {
    visibility: hidden;
  }
}

.spinnerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: center;

  @at-root .loading & {
    display: flex;
  }
}

.secondary {
  --button-background-color: transparent;
  --button-border-color: var(--purple-color);
  --button-text-color: var(--purple-color);
  --badge-border-color: #b9a0f7;

  &:hover {
    --button-border-color: var(--purple-hover-color);
    --button-text-color: var(--purple-hover-color);
    --button-background-color: var(--purple-hover-bg-color);
  }
}

.arrowButton {
  --button-background-color: transparent;

  padding: 0;
  border-radius: 1px;
  color: var(--button-text-color, var(--purple-color));
  font-family: var(--font-headline);
  font-weight: 600;
  font-size: var(--button-font-size, var(--base-font-size, app.rem(20px)));
  border: none;
  text-align: left;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    height: 6px;
    inset: 100% -3px auto 0;
    transition: background-size 0.3s;
    background-size: 0% 0.1em;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
    background-image: linear-gradient(
      to bottom,
      currentColor 100%,
      transparent 100%
    );
  }

  &:hover {
    &:before {
      background-size: 100% 0.1em;
      background-position-x: left;
      background-position-y: bottom;
    }

    .arrow {
      transform: translateX(3px);
    }
  }
}

.arrow {
  height: 0.7em;
  width: auto;
  display: block;
  margin-left: 0.55em;
  transition: transform 0.2s;
  flex: none;

  path {
    fill: currentColor;
  }
}

.badge {
  display: inline-flex;
  font-size: 12px;
  position: relative;
  margin-right: 1.8em;
  padding: 0 1px;

  &:before {
    content: '';
    position: absolute;
    inset: -6px;
    font-size: inherit;
    border-radius: 3px;
    box-shadow: 0 0 0 1px var(--badge-border-color);
    transition: box-shadow 0.14s ease;
  }

  svg {
    width: 1em;
    height: 1em;
    margin-right: 4px;
  }

  @media (width <= 640px) {
    margin-right: 1.6em;
  }
}

.buttonWrapper {
  --gap: var(--button-gap, 15px);

  margin-bottom: calc(var(--gap) * -1);
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &:empty {
    margin-bottom: 0;
  }

  > * {
    margin: 0 var(--gap) var(--gap) 0;
  }
}
