/* --------------------------- */
/* Request a Demo Form */
/* --------------------------- */

.wrapper {
  position: relative;
  margin: 16px 0 -10px 0;
}

.contentWrapper {
  border-radius: 10px;
  background: #fff;
  position: relative;
  z-index: 10;
  display: flex;
  overflow: hidden;
  margin: -10px 0;
  box-shadow: 0px 33px 80px 0px rgba(70, 57, 100, 0.06),
    0px 13.787px 33.422px 0px rgba(70, 57, 100, 0.04),
    0px 7.371px 17.869px 0px rgba(70, 57, 100, 0.04),
    0px 4.132px 10.017px 0px rgba(70, 57, 100, 0.03),
    0px 2.195px 5.32px 0px rgba(70, 57, 100, 0.02),
    0px 0.913px 2.214px 0px rgba(70, 57, 100, 0.02);
}

.content {
  // --padding: 80px;

  flex-basis: 53%;
  max-width: 675px;
  padding: 80px;
  // padding: var(--padding);

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .5rem;

  h1 {
    font-size: clamp(2rem, 4vw, 2.5rem);
    text-wrap: balance;
  }

  p {
    font-size: clamp(1.25rem, 4vw, 1.5rem);
    text-wrap: pretty;
  }
}

.formWrapper {
  margin-top: 40px;
  position: relative;
}

.form {
  // --submit-gap: 60px;

  transition: visibility step-end 0.3s, opacity 0.3s;

  @at-root .hasSucceeded & {
    opacity: 0;
    visibility: hidden;
  }
}

.submit {
  --submit-gap: calc(var(--base-submit-gap) + 10px);

  width: 100%;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  // margin: calc(var(--base-submit-gap) + 10px) auto 0 auto;
  display: block;
}

.success {
  --check-size: 60px;
  --check-color: #40c740;
  --check-ease: cubic-bezier(0.65, 0, 0.45, 1);
  --check-delay: 0.3s;

  position: absolute;
  inset: 0 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  animation: successFadeIn 0.6 forwards;
}

.successText {
  font-size: 16px;
  text-align: center;
  opacity: 0;
  animation: successTextFade 1s ease calc(var(--check-delay) + 0.9s) forwards;
  line-height: 1.3;
  font-weight: 500;

  strong {
    display: block;
    font-size: 1.1em;
    color: var(--headline-text-color);
    font-weight: 600;
    margin-bottom: 8px;
  }
}

.checkmark {
  width: var(--check-size);
  height: var(--check-size);
  flex: none;
  margin-bottom: 22px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: var(--editor-background-color);
  stroke-miterlimit: 10;
  margin-top: -30px;
  box-shadow: inset 0px 0px 0px var(--check-color);
  animation: checkmarkFill 0.4s ease-in-out calc(var(--check-delay) + 0.4s)
      forwards,
    checkmarkScale 0.3s ease-in-out calc(var(--check-delay) + 0.9s) both;

  circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--check-color);
    fill: none;
    animation: checkmarkStroke 0.6s var(--check-ease) var(--check-delay)
      forwards;
  }

  path {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke: #fff;
    animation: checkmarkStroke 0.3s var(--check-ease)
      calc(var(--check-delay) + 0.8s) forwards;
  }
}

@keyframes successFadeIn {
  to {
    opacity: 1;
  }
}

@keyframes checkmarkStroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmarkScale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes checkmarkFill {
  100% {
    box-shadow: inset 0px 0px 0px calc(var(--check-size) / 2) var(--check-color);
  }
}

@keyframes successTextFade {
  100% {
    opacity: 1;
  }
}

.figure {
  flex-basis: 47%;
  flex-grow: 1;
  position: relative;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 70px;
  overflow: hidden;
  background-color: #201543;

  img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    user-select: none;
    pointer-events: none;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    pointer-events: none;
  }

  &:before {
    z-index: 2;
    mix-blend-mode: color;
    background: linear-gradient(
      180deg,
      rgba(107, 59, 246, 0.2) 0%,
      rgba(107, 59, 246, 0.2) 6.67%,
      rgba(107, 59, 246, 0.21) 13.33%,
      rgba(107, 59, 246, 0.23) 20%,
      rgba(107, 59, 246, 0.26) 26.67%,
      rgba(107, 59, 246, 0.29) 33.33%,
      rgba(107, 59, 246, 0.32) 40%,
      rgba(107, 59, 246, 0.37) 46.67%,
      rgba(107, 59, 246, 0.41) 53.33%,
      rgba(107, 59, 246, 0.45) 60%,
      rgba(107, 59, 246, 0.49) 66.67%,
      rgba(107, 59, 246, 0.52) 73.33%,
      rgba(107, 59, 246, 0.54) 80%,
      rgba(107, 59, 246, 0.56) 86.67%,
      rgba(107, 59, 246, 0.57) 93.33%,
      rgba(107, 59, 246, 0.57) 100%
    );
  }

  &:after {
    z-index: 3;
    background: linear-gradient(
      180deg,
      rgba(18, 9, 44, 0) -10.71%,
      rgba(18, 9, 44, 0.01) -4.17%,
      rgba(18, 9, 44, 0.03) 2.38%,
      rgba(18, 9, 43, 0.07) 8.93%,
      rgba(17, 8, 42, 0.13) 15.47%,
      rgba(17, 8, 41, 0.21) 22.02%,
      rgba(16, 8, 40, 0.3) 28.57%,
      rgba(15, 7, 39, 0.4) 35.12%,
      rgba(15, 7, 37, 0.5) 41.66%,
      rgba(14, 6, 36, 0.6) 48.21%,
      rgba(13, 6, 35, 0.69) 54.76%,
      rgba(13, 6, 34, 0.77) 61.3%,
      rgba(12, 5, 33, 0.83) 67.85%,
      rgba(12, 5, 32, 0.87) 74.4%,
      rgba(12, 5, 32, 0.89) 80.95%,
      rgba(12, 5, 32, 0.9) 87.49%
    );
  }
}

.quote {
  color: #fff;
  position: relative;
  z-index: 10;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    content: '';
    width: 52px;
    height: 52px;
    background-image: svg-load('./quote-circle.svg');
    background-size: cover;
    margin-bottom: 40px;
  }

  cite {
    color: #d9ccff;
    font-style: normal;
    font-size: 18px;
  }

  p {
    margin-bottom: 0.6em;
    max-width: 500px;
  }
}

.blobWrapper {
  position: absolute;
  inset: -100px 0 0 0;
  pointer-events: none;
  z-index: 1;
  overflow: hidden;
}

.blob {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: calc(100% - 100px);
  object-fit: contain;
}

@media (height <= 1020px) and (width >= 871px) {
  .wrapper {
    --section-top-padding-offset: -5px;

    margin-top: 0;

    @media (width >= 1301px) {
      padding-top: 60px;
    }
  }

  .content {
    padding: 2rem;
  }

  .blobWrapper {
    transform: scaleX(-1);
    bottom: -50px;
  }

  .blob {
    // top: 75px;
    // left: 80px;
    // width: 85%;
    // transform: rotate(-6deg);
    // height: calc(100% - 160px);
    top: 65px;
    left: 150px;
    width: 85%;
    transform: rotate(-6deg);
    height: calc(100% - 160px);

    @media (width <= 1100px) {
      top: 70px;
      left: 110px;
      height: calc(100% - 130px);
      transform: rotate(-8deg);
    }
  }
}

@media (width <= 1200px) {
  .content {
    padding: 60px;
  }

  .figure {
    padding: 60px 45px;
  }

  .quote {
    font-size: 18px;

    cite {
      font-size: 16px;
    }
  }
}

@media (width <= 1080px) {
  .content,
  .figure {
    flex-basis: 50%;
  }

  .content {
    padding: 50px;
  }

  .formWrapper {
    margin-top: 30px;
  }

  .submit {
    --submit-gap: 45px;

    max-width: none;
  }
}

@media (width <= 870px) {
  .contentWrapper {
    display: block;
  }

  .content {
    max-width: none;
  }

  .blob {
    --blob-offset: 50px;

    left: calc(var(--blob-offset) * -1);
    width: calc(100% + var(--blob-offset) * 2);
    height: auto;
    transform: rotate(330deg);
  }

  .figure {
    min-height: 0;
    height: 480px;
  }
}

@media (width <= 785px) {
  .wrapper {
    margin-top: 10px;
  }

  .blob {
    --blob-offset: 30px;

    transform: rotate(341deg);
  }
}

@media (width <= 660px) {
  .content {
    padding: 45px;
  }

  .figure {
    height: 400px;
    padding: 45px 40px;

    &:before,
    &:after {
      top: -50px;
      bottom: -10px;
    }
  }

  .blobWrapper {
    display: none;
  }
}

@media (width <= 620px) {
  .submit {
    width: 100%;
  }
}

@media (width <= 500px) {
  .wrapper {
    margin-top: 0;
  }
}

@media (width <= 480px) {
  .figure {
    height: 450px;
  }
}

@media (width <= 420px) {
  .content {
    padding: 2rem 1rem;
  }
}

@media (width <= 400px) {
  .figure {
    padding: 45px 35px;
  }

  .quote {
    font-size: 17px;

    cite {
      font-size: 14px;
      margin-top: 5px;
    }
  }
}
