/* --------------------------- */
/* Platform Label */
/* --------------------------- */

.wrapper {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: var(--font-headline);
  font-weight: 600;
  line-height: 1;

  @media (width <= 970px) {
    font-size: 18px;
  }
}

.icon {
  --icon-size: 1.2em;

  flex: none;
  margin-right: 0.55em;
}
