/* --------------------------- */
/* Home Page Hero Animation */
/* --------------------------- */

.wrapper {
  --border-radius: var(--animation-border-radius, 15px);

  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: #fff;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  box-shadow: 0px 2.85px 4.206px 0px rgba(38, 29, 53, 0.01),
    0px 6.85px 10.108px 0px rgba(38, 29, 53, 0.02),
    0px 12.897px 19.033px 0px rgba(38, 29, 53, 0.02),
    0px 23.006px 33.951px 0px rgba(38, 29, 53, 0.02),
    0px 43.031px 63.502px 0px rgba(38, 29, 53, 0.03),
    0px 103px 152px 0px rgba(38, 29, 53, 0.04);
}

.browserButtons {
  position: absolute;
  z-index: 10;
}
