/* --------------------------- */
/* Lines */
/* --------------------------- */

@use 'www/styles/abstracts/app';

:where(.vertical) {
  width: var(--stroke-width, 2px);
  height: 100%;
}

:where(.horizontal) {
  width: 100%;
  height: var(--stroke-width, 2px);
}

.line {
  display: block;
  position: relative;

  line {
    --sw: var(--stroke-width, 2px);

    stroke-width: var(--sw);
    stroke: var(--line-color, var(--light-divider-color));

    @include app.retina {
      stroke-width: var(--retina-stroke-width, calc(var(--sw) - 0.5px));
    }
  }

  &.dark {
    line {
      stroke: var(--border-color, var(--dark-divider-color));
    }
  }
}
