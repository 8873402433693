/* --------------------------- */
/* Spritesheet Icons */
/* --------------------------- */

@use 'www/styles/abstracts/app';

.icon {
  width: var(--icon-width, var(--icon-size));
  height: var(--icon-height, var(--icon-size));
  flex: none;

  @include app.icon('landlords') {
    x: 5.882%;
  }

  &.stroke {
    --sw: var(--stroke-width, 2px);

    fill: none;
    stroke: var(--icon-color, currentColor);
    stroke-width: var(--sw);

    @include app.icon('*') {
      x: calc(var(--sw) * -1);
      y: calc(var(--sw) * -1);
    }
  }
}

.iconText {
  --size: var(--icon-size, #{app.em(18px, 17px)});
  --leading-height: calc(1em * var(--line-height, 1.3));
  --stroke: var(--stroke-width, 2px);
  --color: var(--icon-color, currentColor);

  display: flex;
  line-height: var(--line-height, 1.3);
  font-weight: var(--font-weight, 600);

  &.strokeIcon {
    --size: var(--stroke-icon-size, #{app.em(22px, 17px)});
  }

  .icon {
    --icon-size: var(--size);
    --stroke-width: var(--stroke);
    --icon-color: var(--color);

    position: relative;
    margin-right: var(--icon-spacing, #{app.em(8px, 17px)});
    margin-top: calc(var(--leading-height) / 2 - var(--icon-size) / 2);
  }

  &:global(.icon-text-lock-stroke) {
    --stroke: var(--stroke-width, 2.2px);
  }

  :global {
    .icon-insurance,
    .icon-insurance-stroke {
      top: -1px;
    }
  }
}
